import {
  Button,
  DateField,
  DonutChart,
  HookedForm,
  LineChart,
  SelectField,
} from '@app/components';
import { useAnalyticsRatingsPlatforms } from './AnalyticsRatingsPlatform.hooks';
import ArrowBackIcon from '@app/assets/icons/back.svg?react';
import GoogleIcon from '@app/assets/icons/Google.svg?react';
import FacebookIcon from '@app/assets/icons/Facebook-Small-Logo.svg?react';
import YelpIcon from '@app/assets/icons/integrations/yelp.svg?react';
import TrustpilotIcon from '@app/assets/icons/Trustpilot.svg?react';

export const AnalyticsRatingsPlatform = () => {
  const { navigate } = useAnalyticsRatingsPlatforms();

  return (
    <>
      <div className="bg-white border-b border-b-base-ghostWhite ">
        <div className="container mx-auto p-4 flex justify-between items-center">
          <h2 className="font-bold text-[28px]">
            <button
              onClick={() => navigate('/analytics/ratings')}
              className="mr-2"
            >
              <ArrowBackIcon />
            </button>
            Review count by platform
          </h2>
          <div className="flex gap-2">
            <Button
              kind="secondary"
              className="text-sm font-semibold min-w-fit"
            >
              Export as PDF
            </Button>
          </div>
        </div>
        <div className="container mx-auto p-4">
          <HookedForm className="flex gap-4 ">
            <DateField name="start" containerClass="w-36" />
            <DateField name="end" containerClass="w-36 " />
            <SelectField
              defaultValue="all"
              name="connection"
              options={[{ label: 'All connections', value: 'all' }]}
            />
          </HookedForm>
        </div>
      </div>
      <div className="container mx-auto p-4 space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-white p-4 min-h-[300px]">
            <DonutChart
              opt={{
                legend: {
                  position: 'bottom',
                  alignment: 'center',
                  maxLines: 5,
                },
              }}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white p-4 flex justify-between flex-col gap-2">
              <div className="flex gap-2 items-center">
                <div className="bg-base-antiFlash h-10 w-10 rounded-md flex items-center justify-center">
                  <GoogleIcon />
                </div>
                <p className="text-base-davyGray">Google</p>
              </div>
              <p className="text-3xl font-extrabold">5.0</p>
              <hr />
              <p className="text-xs text-base-coolGray">Based on 304 reviews</p>
            </div>
            <div className="bg-white p-4 flex justify-between flex-col gap-2">
              <div className="flex gap-2 items-center">
                <div className="bg-base-antiFlash h-10 w-10 rounded-md flex items-center justify-center">
                  <FacebookIcon />
                </div>
                <p className="text-base-davyGray">Facebook</p>
              </div>

              <p className="text-3xl font-extrabold">5.0</p>
              <hr />
              <p className="text-xs text-base-coolGray">Based on 304 reviews</p>
            </div>
            <div className="bg-white p-4 flex justify-between flex-col gap-2">
              <div className="flex gap-2 items-center">
                <div className="bg-base-antiFlash h-10 w-10 rounded-md flex items-center justify-center">
                  <YelpIcon />
                </div>
                <p className="text-base-davyGray">Yelp</p>
              </div>
              <p className="text-3xl font-extrabold">5.0</p>
              <hr />
              <p className="text-xs text-base-coolGray">Based on 304 reviews</p>
            </div>
            <div className="bg-white p-4 flex justify-between flex-col gap-2">
              <div className="flex gap-2 items-center">
                <div className="bg-base-antiFlash h-10 w-10 rounded-md flex items-center justify-center">
                  <TrustpilotIcon />
                </div>
                <p className="text-base-davyGray">Trustpilot</p>
              </div>
              <p className="text-3xl font-extrabold">5.0</p>
              <hr />
              <p className="text-xs text-base-coolGray">Based on 304 reviews</p>
            </div>
          </div>
        </div>
        <div className="bg-white p-4 h-fit">
          <div className="flex items-center justify-between">
            <h5 className="font-bold text-sm">Rating by platform over time</h5>
            <HookedForm>
              <SelectField
                defaultValue={'monthly'}
                className="bg-base-antiFlash p-2 rounded-lg"
                options={[{ label: 'Monthly', value: 'monthly' }]}
              />
            </HookedForm>
          </div>
          <hr className="my-4" />
          <div>
            <LineChart
              title="Rating"
              opt={{ legend: { position: 'bottom' } }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
