import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { debounce } from 'lodash';

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  containerClass?: string;
  isOptional?: boolean;
}

export const DateField = ({
  name,
  label,
  containerClass,
  isOptional,
  ...rest
}: InputFieldProps) => {
  const {
    register,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  return (
    <div className={`w-full relative ${containerClass ?? ''}`}>
      {label && (
        <label htmlFor={name} className="font-medium text-sm inline-block mb-1">
          {label} {isOptional && <span>(optional)</span>}
        </label>
      )}
      <input
        {...rest}
        {...(rest.placeholder
          ? isOptional
            ? { placeholder: rest.placeholder + ' (optional)' }
            : { placeholder: rest.placeholder }
          : {})}
        type="date"
        {...register(name ?? '')}
        onChange={debounce((e) => {
          register(name ?? '').onChange(e);
        }, 500)}
        className={clsx(
          `bg-base-seasalt rounded py-3 px-4 w-full outline-brand-baseBlue outline-1 ${rest.className ?? ''}`,
          { 'border border-system-error': name && errors[name] }
        )}
      />

      {name && errors[name] && (
        <p role="alert" className="text-system-error text-sm text-left pt-1">
          {typeof errors?.[name]?.message === 'string'
            ? errors[name]?.message
            : ''}
        </p>
      )}
    </div>
  );
};
