const baseStyles =
  'min-w-[200px] flex items-center justify-center gap-2  p-2 rounded-lg';

export const getButtonByKind = (kind?: string, size?: string) => {
  switch (kind) {
    default:
    case 'primary': {
      return `${baseStyles} bg-brand-baseBlue text-white disabled:opacity-50
        ${size === 'small' ? ' text-sm' : ''}`;
    }
    case 'secondary': {
      return `${baseStyles} bg-base-ghostWhite text-base-blue disabled:opacity-50
        ${size === 'small' ? ' text-sm' : ''}`;
    }
    case 'tertiary': {
      return `${baseStyles} bg-transparent text-base-blue disabled:opacity-50
        ${size === 'small' ? ' text-sm' : ''}`;
    }
    case 'quaternary': {
      return `${baseStyles} bg-brand-lightBlue3 text-brand-darkBlue3 disabled:opacity-50
        ${size === 'small' ? ' text-sm' : ''}`;
    }
    case 'danger': {
      return `${baseStyles} bg-system-error text-white disabled:opacity-50
        ${size === 'small' ? ' text-sm' : ''}`;
    }
  }
};
