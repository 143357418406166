import { OptionInterface } from '@app/models/util';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

export const Tabs = ({
  tabs,
  onClick,
  defaultValue,
}: {
  tabs: OptionInterface[];
  onClick?: (value: string) => void;
  defaultValue?: string;
}) => {
  const [activeTab, setActiveTab] = useState(defaultValue ?? tabs?.[0]?.value);

  useEffect(() => {
    defaultValue && setActiveTab(defaultValue);
  }, [defaultValue]);

  return (
    <div>
      <ul className="flex gap-2">
        {tabs.map((tab) => (
          <li
            key={tab.value}
            onClick={() => {
              setActiveTab(tab.value);
              onClick && onClick(tab.value);
            }}
            className={clsx(
              'text-base-coolGray text-sm font-medium py-2 px-4 cursor-pointer',
              {
                'border-b-2 border-b-base-raisinBlack text-base-raisinBlack':
                  activeTab === tab.value,
              }
            )}
          >
            {tab.label}
          </li>
        ))}
      </ul>
    </div>
  );
};
