import { AnimationLayout, AuthGuard, DashboardLayout } from '@app/components';
import {
  Contacts,
  CustomerView,
  Dashboard,
  ForgotPassword,
  ImportFromFile,
  IntegrationsCRM,
  IntegrationsReviews,
  Lists,
  Login,
  NewBusiness,
  NewPassword,
  ProfileSettings,
  SelectBusiness,
  Settings,
  SignUp,
  ValidateEmail,
  Welcome,
  ListView,
  AnalyticsRatings,
  AnalyticsCampaigns,
  YourCurrentPlan,
} from '@app/pages';
import { AnalyticsRatingsPlatform } from '@app/pages/AnalyticsRatingsPlatform/AnalyticsRatingsPlatform';
import { Automations } from '@app/pages/Automations/Automations';
import { Campaigns } from '@app/pages/Campaigns/Campaigns';
import { Onboarding } from '@app/pages/Onboarding/Onboarding';
import { Ratings } from '@app/pages/Ratings/Ratings';
import { createBrowserRouter } from 'react-router-dom';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AnimationLayout />,
    children: [
      {
        path: '/login',
        element: <Login />,
        handle: {
          crumb: () => 'Login',
        },
      },
      {
        path: '/validate-email/:token',
        element: <ValidateEmail />,
        handle: {
          crumb: () => 'Validate Email',
        },
      },
      {
        path: '/sign-up',
        element: <SignUp />,
        handle: {
          crumb: () => 'Sign Up',
        },
      },
      {
        path: '/welcome',
        element: <Welcome />,
        handle: {
          crumb: () => 'Welcome',
        },
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />,
        handle: {
          crumb: () => 'Forgot Password',
        },
      },
      {
        path: '/reset-password/:token',
        element: <NewPassword />,
        handle: {
          crumb: () => 'Reset Password',
        },
      },

      {
        element: <AuthGuard />,
        children: [
          {
            path: '/select-business',
            element: <SelectBusiness />,
            handle: {
              crumb: () => 'Select Business',
            },
          },
          {
            path: '/select-business/create-new',
            element: <NewBusiness />,
            handle: {
              crumb: () => 'Create new Business',
            },
          },
          {
            element: <DashboardLayout />,
            children: [
              {
                path: '/onboarding',
                element: <Onboarding />,
                handle: {
                  crumb: () => 'Onboarding',
                },
              },
              {
                path: '/automations',
                element: <Automations />,
                handle: {
                  crumb: () => 'Automations',
                },
              },
              {
                path: '/campaigns',
                element: <Campaigns />,
                handle: {
                  crumb: () => 'Campaigns',
                },
              },
              {
                path: '/ratings',
                element: <Ratings />,
                handle: {
                  crumb: () => 'Ratings',
                },
              },
              {
                index: true,
                path: '/',
                element: <Dashboard />,
                handle: {
                  crumb: () => 'Dashboard',
                },
              },
              {
                index: true,
                path: '/customers/contacts',
                element: <Contacts />,
                handle: {
                  crumb: () => 'Customers > Contacts',
                },
              },
              {
                index: true,
                path: '/customers/contacts/:id',
                element: <CustomerView />,
                handle: {
                  crumb: () => 'Contacts > Contacts',
                },
              },
              {
                index: true,
                path: '/customers/contacts/import-file',
                element: <ImportFromFile />,
                handle: {
                  crumb: () => 'Customers > Import from file',
                },
              },
              {
                index: true,
                path: '/analytics/ratings',
                element: <AnalyticsRatings />,
                handle: {
                  crumb: () => 'Analytics > Ratings',
                },
              },
              {
                index: true,
                path: '/analytics/ratings/platforms',
                element: <AnalyticsRatingsPlatform />,
                handle: {
                  crumb: () => 'Analytics > Ratings > Platforms',
                },
              },
              {
                index: true,
                path: '/analytics/campaigns',
                element: <AnalyticsCampaigns />,
                handle: {
                  crumb: () => 'Analytics > Campaigns',
                },
              },

              {
                index: true,
                path: '/customers/lists',
                element: <Lists />,
                handle: {
                  crumb: () => 'Customers > Lists',
                },
              },
              {
                index: true,
                path: '/customers/lists/:id',
                element: <ListView />,
                handle: {
                  crumb: () => 'Customers > Lists',
                },
              },
              {
                path: '/integrations',
                element: <Settings />,
                handle: {
                  crumb: () => 'Integrations',
                },
              },
              {
                path: '/integrations/reviews',
                element: <IntegrationsReviews />,
                handle: {
                  crumb: () => 'Integrations > Reviews',
                },
              },
              {
                path: '/integrations/crm',
                element: <IntegrationsCRM />,
                handle: {
                  crumb: () => 'Integrations > CRM Connection',
                },
              },
              {
                path: '/settings',
                element: <Settings />,
                handle: {
                  crumb: () => 'Settings',
                },
              },
              {
                index: true,
                path: '/settings/plan/details',
                element: <YourCurrentPlan />,
                handle: {
                  crumb: () => 'Settings > Plan Details',
                },
              },
              {
                path: '/profile',
                element: <ProfileSettings />,
                handle: {
                  crumb: () => 'Profile Settings',
                },
              },
            ],
          },
        ],
      },
    ],
  },
]);
