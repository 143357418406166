import { CustomError } from '@app/models/general';
import { useAppDispatch } from '@app/redux/hooks';
import { ActionTypes } from '@app/redux/types';
import { login, loginWithGoogle } from '@app/services/auth';
import { getProfile } from '@app/services/user';
import { loginValidation } from '@app/utils/validations';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useLoginPage = () => {
  const dispatch = useAppDispatch();
  const [loginError, setLoginError] = useState<string | undefined>();
  const loginForm = useForm({ resolver: loginValidation });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { mutate: loginFn, isPending: isLoginPending } = useMutation({
    mutationKey: ['standard-login'],
    mutationFn: (data: FieldValues) => login(data?.email, data?.password),
    onSuccess: (res) => {
      localStorage.setItem('accessToken', res?.data?.accessToken ?? '');
      dispatch({ type: 'auth/save', payload: res?.data?.user });
      dispatch({
        type: 'business/loadList',
        payload: res?.data?.businesses,
      });
      if (res?.data?.businesses?.length === 0) {
        navigate('/select-business/create-new');
      }
      if (res?.data?.businesses?.length === 1) {
        dispatch({
          type: 'business/selectBusiness',
          payload: { id: res?.data?.businesses[0].id },
        });
        navigate('/');
      } else {
        navigate('/select-business');
      }
    },
    onError: (err: CustomError) => {
      setLoginError(err?.data?.message);
    },
  });

  const { mutate: googleLoginFn, isPending: isPendingGoogle } = useMutation({
    mutationKey: ['google-login'],
    mutationFn: () => loginWithGoogle(),
    onSuccess: () => {},
    onError: () => {},
  });

  const { mutate: getProfileWithBussinessFn, isPending: isPendingProfile } =
    useMutation({
      mutationKey: ['get-user-business'],
      mutationFn: () => getProfile(),
      onSuccess: (res) => {
        dispatch({ type: ActionTypes.AUTH_SAVE, payload: res?.data?.user });
        dispatch({
          type: ActionTypes.BUSINESS_LOAD_LIST,
          payload: res?.data?.businesses,
        });
        if (res?.data?.businesses?.length === 0) {
          navigate('/select-business/create-new');
        }
        if (res?.data?.businesses?.length === 1) {
          dispatch({
            type: 'business/selectBusiness',
            payload: { id: res?.data?.businesses[0].id },
          });
          navigate('/');
        } else {
          navigate('/select-business');
        }
      },
      onError: (err: CustomError) => {
        console.error(err);
        setLoginError(err?.data?.message);
      },
    });

  const submitLogin = async (values: FieldValues) => {
    setLoginError(undefined);

    loginFn(values);
  };

  useEffect(() => {
    if (searchParams.get('token')) {
      localStorage.setItem('accessToken', searchParams?.get('token') ?? '');
      getProfileWithBussinessFn();
    }
  }, []);

  return {
    loginForm,
    submitLogin,
    loginError,
    googleLoginFn,
    isLoading: isLoginPending || isPendingGoogle || isPendingProfile,
  };
};
