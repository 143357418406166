import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from '../withTypes';
import { getBusinesses } from '@app/services/user';
import { getCategories } from '@app/services/business';
import { authSlice } from './auth';
import { CustomerInterface } from '@app/models/business';

export interface BusinessState {
  list: { id: string; name: string }[];
  selected: { id: string; name: string } | undefined;
  categories: { id: string; name: string }[];
  customers: CustomerInterface[];
  isCategoriesFetched: boolean;
}

const initialState: BusinessState = {
  list: [],
  categories: [],
  customers: [],
  selected: undefined,
  isCategoriesFetched: false,
};

export const fetchBusiness = createAppAsyncThunk(
  'business/fetchBusiness',
  async () => {
    const response = await getBusinesses();
    return response?.data?.businesses;
  }
);

export const fetchBusinessCategories = createAppAsyncThunk(
  'business/fetchBusinessCategories',
  async () => {
    const response = await getCategories();
    return response?.data?.categories;
  }
);

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    saveCustomers: (state, action: PayloadAction<CustomerInterface[]>) => {
      state.customers = action.payload;
    },
    saveCategories: (
      state,
      action: PayloadAction<{ id: string; name: string }[]>
    ) => {
      state.categories = action.payload;
    },
    loadList: (
      state,
      action: PayloadAction<{ id: string; name: string }[]>
    ) => {
      state.list = action.payload;
    },
    addBusiness: (
      state,
      action: PayloadAction<{ id: string; name: string }>
    ) => {
      if (state.list?.some((bus) => bus.id === action.payload.id)) {
        return;
      } else {
        state.list?.push(action.payload);
      }
    },
    updateBusiness: (
      state,
      action: PayloadAction<{ id: string; data: { name: string } }>
    ) => {
      if (state.list?.some((bus) => bus.id === action.payload.id)) {
        state.list = state.list?.map((bus) =>
          bus.id === action.payload.id
            ? { ...bus, ...action.payload.data }
            : bus
        );
      }
    },
    deleteBusiness: (state, action: PayloadAction<{ id: string }>) => {
      state.list = state.list?.filter((bus) => bus.id !== action.payload.id);
    },
    selectBusiness: (state, action: PayloadAction<{ id: string }>) => {
      const selected = state.list?.find((bus) => bus.id === action.payload.id);

      state.selected = selected;
      localStorage.setItem('businessId', selected?.id ?? '');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBusiness.fulfilled, (state, action) => {
      state.list = action.payload;
    });
    builder.addCase(fetchBusinessCategories.fulfilled, (state, action) => {
      state.isCategoriesFetched = true;
      state.categories = action.payload;
    });
    builder.addCase(authSlice.actions.save, (state) => {
      async () => {
        const response = await getBusinesses();
        state.list = response?.data?.businesses;
      };
    });

    builder.addCase(authSlice.actions.logout, (state) => {
      state.categories = [];
      state.list = [];
      state.selected = undefined;
    });
  },
});

export const { addBusiness, updateBusiness, deleteBusiness, selectBusiness } =
  businessSlice.actions;

export default businessSlice.reducer;
