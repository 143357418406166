import { apiInstance } from './api';
import { ApiRoutes } from './consts';

export const getPlans = async () => {
  return apiInstance.get(ApiRoutes.Plan);
};

export const updatePlan = async (priceId: string) => {
  return apiInstance.put(`${ApiRoutes.Plan}/update`, {
    priceId,
  });
};

export const createIntent = async () => {
  return apiInstance.post(`${ApiRoutes.Plan}/intent`);
};

export const getInvoices = async (data: { subId: string }) => {
  return apiInstance.post(`${ApiRoutes.Plan}/invoices`, { ...data });
};
