import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Link } from 'react-router-dom';
import UserIcon from '@app/assets/icons/menu/user.svg?react';
import DotsIcon from '@app/assets/icons/menu/dot-vertical.svg?react';
import BinIcon from '@app/assets/icons/bin.svg?react';
import AddListIcon from '@app/assets/icons/add-list.svg?react';
import ScheduleIcon from '@app/assets/icons/schedule-campaing.svg?react';

export const CustomerMenu = ({
  id,
  addToListAction,
  scheduleCampaignAction,
  deleteCustomerAction,
  simplified,
}: {
  id: string;
  addToListAction?: () => void;
  scheduleCampaignAction?: () => void;
  deleteCustomerAction?: () => void;
  simplified?: boolean;
}) => {
  return (
    <Menu>
      <MenuButton className="group/user flex items-center justify-center gap-2">
        <DotsIcon />
      </MenuButton>
      <MenuItems
        transition
        anchor="left start"
        className="p-2 bg-white shadow-lg mt-2 min-w-[220px] transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 rounded-lg border file:border-base-antiFlash"
      >
        <MenuItem>
          <Link
            className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4 text-xs w-full"
            to={`/customers/contacts/${id}`}
          >
            <UserIcon className="[&_path]:stroke-brand-darkBlue3" /> Open
            profile
          </Link>
        </MenuItem>

        {!simplified && (
          <>
            <MenuItem>
              <button
                className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4 text-xs w-full"
                {...(addToListAction && { onClick: addToListAction })}
              >
                <AddListIcon className="[&_path]:stroke-brand-darkBlue3" /> Add
                to list(s)
              </button>
            </MenuItem>
            <MenuItem>
              <button
                className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4 text-xs w-full"
                {...(scheduleCampaignAction && {
                  onClick: scheduleCampaignAction,
                })}
              >
                <ScheduleIcon className="[&_path]:stroke-brand-darkBlue3" />{' '}
                Schedule new campaign
              </button>
            </MenuItem>
          </>
        )}
        <MenuItem>
          <button
            className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4 text-xs w-full"
            {...(deleteCustomerAction && { onClick: deleteCustomerAction })}
          >
            <BinIcon className="[&_path]:stroke-system-error" /> Delete customer
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
};
