import { CreateCustomerRequest } from '@app/models/requests';
import { apiInstance } from './api';
import { ApiRoutes } from './consts';
import { CustomerInterface } from '@app/models/business';

export const createCustomer = async (data: CreateCustomerRequest) => {
  return apiInstance.post(
    `${ApiRoutes.Business}${ApiRoutes.Customers}/create`,
    data
  );
};

export const getCustomers = async () => {
  return apiInstance.get(`${ApiRoutes.Business}${ApiRoutes.Customers}/all`);
};

export const getPaginatedCustomers = async (params?: {
  text?: string;
  source?: string;
  status?: string;
  rating?: string;
  lists?: string;
  tags?: string;
  channelType?: string;
  pagination?: { page: number; skip: number; limit: number };
  sort?: { field: string; order: 'ASC' | 'DESC' };
}) => {
  const searchParams = new URLSearchParams();
  if (params) {
    if (params.text) {
      searchParams.append('text', params.text);
    }
    if (params.source) {
      searchParams.append('source', params.source);
    }
    if (params.status) {
      searchParams.append('status', params.status);
    }
    if (params.channelType) {
      searchParams.append('channelType', params.channelType);
    }
    if (params.rating) {
      searchParams.append('rating', params.rating);
    }
    if (params.lists) {
      searchParams.append('lists', params.lists);
    }
    if (params.tags) {
      searchParams.append('tags', params.tags);
    }
    if (params.sort) {
      searchParams.append('order', `${params.sort.field}`);
      searchParams.append('sort', `${params.sort.order}`);
    }
    if (params.pagination) {
      searchParams.append('page', params.pagination.page.toString());
      searchParams.append('skip', params.pagination.skip.toString());
      searchParams.append('limit', params.pagination.limit.toString());
    }
  }

  if (params) {
    return apiInstance.get(
      `${ApiRoutes.Business}${ApiRoutes.Customers}/?${searchParams}`
    );
  }

  return apiInstance.get(`${ApiRoutes.Business}${ApiRoutes.Customers}/`, {
    params: { page: 1, limit: 10 },
  });
};

export const getCustomer = async (id: string) => {
  return apiInstance.get(`${ApiRoutes.Business}${ApiRoutes.Customers}/${id}`);
};

export const updateCustomer = async (
  id: string,
  data: Partial<CustomerInterface>
) => {
  return apiInstance.put(
    `${ApiRoutes.Business}${ApiRoutes.Customers}/${id}`,
    data
  );
};

export const uploadCustomers = async (data: CreateCustomerRequest[]) => {
  return apiInstance.post(
    `${ApiRoutes.Business}${ApiRoutes.Customers}/batch`,
    data
  );
};

export const updateCustomers = async (data: {
  customers: Partial<CustomerInterface>[];
  lists?: string[];
}) => {
  return apiInstance.patch(
    `${ApiRoutes.Business}${ApiRoutes.Customers}/batch`,
    data
  );
};

export const deleteCustomers = async (ids: string[]) => {
  return apiInstance.delete(`${ApiRoutes.Business}${ApiRoutes.Customers}/`, {
    data: { ids },
  });
};
