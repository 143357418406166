import BulbIcon from '@app/assets/icons/menu/bulb.svg?react';
import { Button } from '@app/components';
import { ContactUsEmail } from '@app/utils/consts';

export const IntegrationsCRM = () => {
  return (
    <div>
      <div className="bg-white border-b border-b-base-ghostWhite ">
        <div className="container mx-auto p-4 flex flex-col md:flex-row justify-between gap-4">
          <div>
            <h2 className="font-bold text-[28px]">CRM connection</h2>
            <p className="text-base-coolGray">
              Choose a CRM to easily import your customer list. Full integration
              with these CRMs ensures that
              <br /> your customer database is centralized here.
            </p>
          </div>
          <div>
            <div className="bg-brand-lightBlue3 p-4  rounded-lg text-sm font-medium text-brand-darkBlue1 flex gap-2">
              <div>
                <BulbIcon
                  className="[&_path]:stroke-brand-baseBlue"
                  width={20}
                  height={20}
                />
              </div>
              <div>
                <p>
                  Want to integrate a CRM that is not listed
                  <br /> here? Contact us!
                  <br /> And we will consider the possibility of adding it
                  <br /> to the list of integrations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="my-16">
          <div className="max-w-xl mx-auto border-dashed border-2 border-base-lavender p-6 space-y-6 text-center rounded-xl">
            <h5 className="font-bold">Don't see your CRM?</h5>
            <p className="text-base-davyGray">
              Click here and request that it be added
            </p>
            <a href={`mailto:${ContactUsEmail}`} className="inline-block">
              <Button className="mx-auto">Contact us</Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
