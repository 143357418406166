import {
  createIntegration,
  deleteIntegration,
  getIntegrations,
  updateIntegration,
} from '@app/services/integrations';
import { availableIntegrations, IntegrationType } from '@app/utils/consts';
import { getIntegrationIcon } from '@app/utils/utils';
import {
  createIntegrationValidation,
  updateIntegrationValidation,
} from '@app/utils/validations';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

export const useIntegrationsReviews = () => {
  const [integrationFormModal, setIntegrationFormModal] = useState<
    string | undefined
  >();
  const [updateIntegrationFormModal, setUpdateIntegrationFormModal] = useState<
    string | undefined
  >();
  const saveIntegrationForm = useForm({
    resolver: createIntegrationValidation,
  });
  const updateIntegrationForm = useForm({
    resolver: updateIntegrationValidation,
  });

  const handleIntegrationModal = (type?: string) => {
    setIntegrationFormModal(type);

    if (!type) {
      saveIntegrationForm.reset();
    }
  };

  const handleUpdateIntegrationModal = (type?: string) => {
    setUpdateIntegrationFormModal(type);
    if (!type) {
      updateIntegrationForm.reset();
    }
  };

  const {
    data: integrationsResponse,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['get-integrations'],
    queryFn: () => getIntegrations(),
  });

  const saveIntegrationSubmit = (data: {
    type: string;
    connectionId?: string;
  }) => {
    createIntegration({
      type: data.type as IntegrationType,
      connectionId: data.connectionId,
    })
      .then(() => {
        setIntegrationFormModal(undefined);
        saveIntegrationForm.reset();
        refetch();
        toast.success('Integration created');
      })
      .catch((err) => {
        console.error(err);
        toast.error('Error creating integration');
      });
  };

  const deleteIntegrationAction = (type: string) => {
    const integrationInfo = integrationsResponse?.data?.integrations?.find(
      (conn: { type: string }) => conn.type === type
    );
    deleteIntegration(integrationInfo.id)
      .then(() => {
        refetch();
        toast.success('Integration removed');
      })
      .catch((err) => {
        console.error(err);
        toast.error('Error while removing integration');
      });
  };

  const updateIntegrationSubmit = (data: {
    type: string;
    connectionId?: string;
  }) => {
    const integrationInfo = integrationsResponse?.data?.integrations?.find(
      (conn: { type: string }) => conn.type === data.type
    );

    updateIntegration(integrationInfo.id, {
      connectionId: data.connectionId,
    })
      .then(() => {
        setUpdateIntegrationFormModal(undefined);
        updateIntegrationForm.reset();
        refetch();
        toast.success('Integration updated');
      })
      .catch((err) => {
        console.error(err);
        toast.error('Error updating integration');
      });
  };

  const listOfIntegrations = useMemo(() => {
    return Array.from(Object.values(IntegrationType)).map((integration) => {
      const Icon = getIntegrationIcon(integration);

      const hasConnection = integrationsResponse?.data?.integrations?.some(
        (conn: { type: string }) => conn.type === integration
      );
      const available =
        availableIntegrations.includes(integration) && !hasConnection;

      return {
        title: integration?.replace('_', ' '),
        Icon,
        available,
        hasConnection,
      };
    });
  }, [integrationsResponse]);

  return {
    saveIntegrationForm,
    saveIntegrationSubmit,
    handleIntegrationModal,
    integrationFormModal,
    integrationsResponse,
    error,
    isLoading,
    deleteIntegrationAction,
    listOfIntegrations,
    updateIntegrationFormModal,
    handleUpdateIntegrationModal,
    updateIntegrationForm,
    updateIntegrationSubmit,
  };
};
