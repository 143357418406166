import { CheckboxField } from '../Fields/CheckboxField';
import { HookedForm } from '../HookedForm';
import { CustomerInterface } from '@app/models/business';
import { useCustomerTable } from './CustomerTable.hooks';
import { Pagination } from '../Pagination/Pagination';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { CustomerTableRow } from './CustomerTableRow';
import { BaseDialog } from '../BaseDialog/BaseDialog';
import { Button } from '../Button/Button';
import { QueryObserverResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import ScheduleCampaingIcon from '@app/assets/icons/schedule-campaing.svg?react';
import AddToListIcon from '@app/assets/icons/add-list.svg?react';
import DeleteIcon from '@app/assets/icons/bin.svg?react';
import FolderIcon from '@app/assets/icons/folder.svg?react';
import UserIcon from '@app/assets/icons/menu/user.svg?react';
import CloseIcon from '@app/assets/icons/close.svg?react';
import SortIcon from '@app/assets/icons/sort.svg?react';
import ChevronIcon from '@app/assets/icons/chevron-down.svg?react';

const tableHeader = [
  { label: 'CUSTOMER', field: 'name' },
  { label: 'EMAIL/PHONE NUMBER', field: 'email' },
  { label: 'LISTS', field: 'lists' },
  { label: 'TAGS', field: 'tags' },
  { label: 'STATUS', field: 'status' },
  { label: 'RATINGS', field: 'rating' },
];

const simplifiedHeader = [
  { label: 'CUSTOMER', field: 'name' },
  { label: 'EMAIL/PHONE NUMBER', field: 'email' },
  { label: 'STATUS', field: 'status' },
  { label: 'RATINGS', field: 'rating' },
];

export const ListPill = ({ name }: { name: string }) => {
  return (
    <span className="bg-base-antiFlash py-1 px-1.5 text-xs text-base-davyGray inline-flex gap-2 items-center rounded-sm">
      <FolderIcon />
      <span>{name}</span>
    </span>
  );
};

export const TagPill = ({ name }: { name: string }) => {
  return (
    <span className="bg-base-antiFlash py-1 px-1.5 text-xs text-base-davyGray inline-flex gap-2 items-center rounded-sm">
      {name}
    </span>
  );
};

export const CustomerTable = ({
  customers,
  totalCustomers,
  handleNextPage,
  handlePrevPage,
  handleLimitChange,
  externalForm,
  currentPage,
  handleScheduleCampaing,
  handleAddToList,
  handleDeleteCustomer,
  handleSortingTable,
  handleCustomerSelection,
  refetch,
  simplified,
}: {
  customers: CustomerInterface[];
  totalCustomers: number;
  handleNextPage: (page?: number) => void;
  handlePrevPage: (page?: number) => void;
  handleLimitChange: (limit?: number) => void;
  handleScheduleCampaing?: () => void;
  handleAddToList?: (customer?: CustomerInterface) => void;
  handleDeleteCustomer?: () => void;
  handleSortingTable: (field: string) => void;
  handleCustomerSelection: (customerId?: string) => void;
  externalForm?: UseFormReturn<FieldValues, any, undefined>;
  currentPage?: string | number;
  refetch?: () => Promise<QueryObserverResult<AxiosResponse<any, any>, Error>>;
  simplified?: boolean;
}) => {
  const [activeSort, setActiveSort] = useState<{
    field: string;
    order: 'ASC' | 'DESC';
  }>();

  const {
    customerTableForm,
    deleteCustomerFn,
    deleteOpen,
    handleDeleteCustomer: handleSingleCustomerDelete,
    isPendingDelete,
  } = useCustomerTable(refetch);

  const usedForm = externalForm ?? customerTableForm;
  const selectedCustomers = Array.isArray(
    usedForm.getValues('selectedCustomers')
  )
    ? usedForm.getValues('selectedCustomers')
    : [];

  const hasSelected = selectedCustomers?.length > 0;
  const allCustomersId = selectedCustomers?.map(
    (cus: CustomerInterface) => cus.id
  );
  const isAllSelected = (customers ?? []).every((cus: CustomerInterface) =>
    allCustomersId?.includes(cus.id)
  );

  return (
    <>
      <div className="bg-white overflow-x-auto">
        <HookedForm
          formHook={externalForm ?? customerTableForm}
          mode="onChange"
        >
          <table className="w-full">
            <thead>
              <tr className="border-b border-base-antiFlash">
                <th className="p-4 min-w-fit w-0">
                  <CheckboxField
                    name="selectAll"
                    value="all"
                    checked={isAllSelected}
                    onCheckMark={handleCustomerSelection}
                  />
                </th>
                {!hasSelected && (
                  <>
                    {' '}
                    {simplified
                      ? simplifiedHeader.map((item, index) => (
                          <th
                            key={index}
                            className="p-4 text-xs font-medium text-left text-base-coolGray"
                          >
                            {item.label}
                          </th>
                        ))
                      : tableHeader.map((item, index) => (
                          <th
                            key={index}
                            className="p-4 text-xs font-medium text-left text-base-coolGray"
                          >
                            <button
                              className="flex items-center gap-1"
                              onClick={() => {
                                handleSortingTable(item.field);
                                setActiveSort({
                                  field: item.field,
                                  order:
                                    activeSort?.order === 'ASC'
                                      ? 'DESC'
                                      : 'ASC',
                                });
                              }}
                            >
                              {item.label}
                              {activeSort?.field === item.field ? (
                                activeSort.order === 'ASC' ? (
                                  <ChevronIcon className="rotate-180" />
                                ) : (
                                  <ChevronIcon />
                                )
                              ) : (
                                <SortIcon />
                              )}
                            </button>
                          </th>
                        ))}
                  </>
                )}
                {hasSelected && (
                  <th
                    colSpan={5}
                    className="text-sm text-brand-baseBlue font-medium"
                    align="left"
                  >
                    <div className="flex gap-4 ">
                      <button
                        {...(handleScheduleCampaing && {
                          onClick: handleScheduleCampaing,
                        })}
                        className="flex items-center gap-1"
                      >
                        <ScheduleCampaingIcon /> Schedule new campaing
                      </button>
                      <button
                        {...(handleAddToList && {
                          onClick: () => handleAddToList(),
                        })}
                        className="flex items-center gap-1"
                      >
                        <AddToListIcon /> Add to list(s)
                      </button>
                      <button
                        {...(handleDeleteCustomer && {
                          onClick: () => handleDeleteCustomer(),
                        })}
                        className="flex items-center gap-1"
                      >
                        <DeleteIcon /> Delete customer(s)
                      </button>
                    </div>
                  </th>
                )}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {customers?.map((customer) => {
                const isSelected = (
                  Array.isArray(usedForm.getValues('selectedCustomers'))
                    ? usedForm.getValues('selectedCustomers')
                    : []
                )?.find((cus: CustomerInterface) => cus.id === customer.id);

                return (
                  <CustomerTableRow
                    key={customer.id}
                    checked={Boolean(isSelected)}
                    simplified={simplified}
                    customer={customer}
                    addToListAction={() => {
                      handleAddToList && handleAddToList(customer);
                    }}
                    deleteCustomerAction={handleSingleCustomerDelete}
                    scheduleCampaignAction={() => {
                      handleScheduleCampaing && handleScheduleCampaing();
                    }}
                    handleCheckbox={handleCustomerSelection}
                  />
                );
              })}
            </tbody>
          </table>
          <Pagination
            page={Number(currentPage)}
            total={totalCustomers}
            handleLimitChange={handleLimitChange}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
          />
        </HookedForm>
      </div>
      <BaseDialog
        size="max-w-2xl"
        open={Boolean(deleteOpen)}
        onClose={handleSingleCustomerDelete}
      >
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <UserIcon width={24} height={24} />
          </div>
          <div className="mr-auto ">
            <h5 className="font-bold text-lg leading-tight">Delete customer</h5>
            <p className="text-base-davyGray text-sm">
              Are you sure you want to delete this customer?
            </p>
          </div>
          <div>
            <button type="button" onClick={() => handleSingleCustomerDelete()}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6 pb-4">
          <p className="text-base-davyGray text-sm">
            The following customer will be deleted from all locations in
            EchoRatings. Customers connected through the CRM system will not be
            deleted from your CRM.
          </p>

          <div className="flex items-center my-6 gap-2">
            <div className="w-6 h-6 bg-base-antiFlash flex items-center justify-center rounded-sm">
              <UserIcon
                width={15}
                height={15}
                className="[&_path]:stroke-base-coolGray"
              />
            </div>
            <span>
              {deleteOpen?.firstName} {deleteOpen?.lastName}
            </span>
          </div>

          <div className="flex items-center gap-4 justify-center">
            <Button
              kind="secondary"
              className="w-full"
              type="button"
              onClick={() => handleSingleCustomerDelete()}
            >
              Cancel
            </Button>
            <Button
              kind="danger"
              disabled={isPendingDelete || !deleteOpen}
              loading={isPendingDelete}
              className="w-full"
              type="submit"
              onClick={() => {
                deleteCustomerFn(deleteOpen as CustomerInterface);
              }}
            >
              Delete customer
            </Button>
          </div>
        </div>
      </BaseDialog>
    </>
  );
};
