import DiamondIcon from '@app/assets/icons/diamond.svg?react';
import EnterpriseIcon from '@app/assets/icons/enterprise.svg?react';
import AnalyticsIcon from '@app/assets/icons/menu/analytics.svg?react';
import AutomationsIcon from '@app/assets/icons/menu/automations.svg?react';
import CampaignsIcon from '@app/assets/icons/menu/campaigns.svg?react';
import CustomersIcon from '@app/assets/icons/menu/customers.svg?react';
import RatingsIcon from '@app/assets/icons/menu/ratings.svg?react';
import IntegrationsIcon from '@app/assets/icons/menu/integrations.svg?react';
import DashboardIcon from '@app/assets/icons/menu/dashboard.svg?react';
import OnboardingIcon from '@app/assets/icons/menu/onboarding.svg?react';
import BriefcaseIcon from '@app/assets/icons/briefcase.svg?react';

interface SideMenuItem {
  label: string;
  to: string;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  children?: SideMenuItem[];
}

export enum MembershipTypes {
  Basic = 'basic',
  Essential = 'essential',
  Professional = 'professional',
  Enterprise = 'enterprise',
}

export enum CustomerStatus {
  NO_STATUS = 'null',
  REVIEWD = 'reviewed',
  CAMPAIGN_SENT = 'campaign_sent',
  FEEDBACK = 'feedback',
  UNSUBSCRIBED = 'unsubscribed',
}

export enum SettingTabs {
  DETAILS = 'details',
  MEMBERS = 'members',
  PAYMENT_METHODS = 'payment-methods',
  PLAN = 'plan',
}

export const CustomerStatusMap = new Map([
  [CustomerStatus.NO_STATUS, { label: 'No status', color: 'bg-base-coolGray' }],
  [CustomerStatus.REVIEWD, { label: 'Reviewed', color: 'bg-system-success' }],
  [
    CustomerStatus.CAMPAIGN_SENT,
    { label: 'Campaign sent', color: 'bg-brand-baseBlue' },
  ],
  [CustomerStatus.FEEDBACK, { label: 'Feedback', color: 'bg-system-warning' }],
  [
    CustomerStatus.UNSUBSCRIBED,
    { label: 'Unsubscribed', color: 'bg-system-error' },
  ],
]);

export const SettingsTabs = [
  { label: 'Business details', value: SettingTabs.DETAILS },
  { label: 'Team members', value: SettingTabs.MEMBERS },
  { label: 'Payment methods', value: SettingTabs.PAYMENT_METHODS },
  { label: 'Plan', value: SettingTabs.PLAN },
];

export const CustomerTabs = [
  { label: 'Profile Information', value: 1 },
  { label: 'Campaings', value: 2 },
  { label: 'Activity', value: 3 },
];

export enum IntegrationType {
  YELP = 'yelp',
  GOOGLE = 'google',
  TRUSTPILOT = 'trustpilot',
  FACEBOOK = 'facebook',
}

export const availableIntegrations = [IntegrationType.YELP];

export const planNames = [
  {
    name: MembershipTypes.Basic,
    price: 67,
    Icon: BriefcaseIcon,
    class: 'blue-to-pink',
    color: '[&_path]:stroke-blue-500',
  },
  {
    name: MembershipTypes.Essential,
    price: 97,
    Icon: DiamondIcon,
    class: 'blue-to-green',
    color: '[&_path]:stroke-[#4ada73]',
  },
  {
    name: MembershipTypes.Professional,
    price: 147,
    Icon: EnterpriseIcon,
    class: 'blue-to-dark-blue',
    color: '[&_path]:stroke-[#52b6fe]',
  },
];

export const plansAndBenefits = [
  {
    title: 'Member seats',
    values: [1, 5, 20],
    plansWith: [
      MembershipTypes.Basic,
      MembershipTypes.Essential,
      MembershipTypes.Professional,
    ],
  },
  {
    title: 'Outreach Attempts (up to)',
    values: [300, 1000, 10000],
    plansWith: [
      MembershipTypes.Basic,
      MembershipTypes.Essential,
      MembershipTypes.Professional,
    ],
  },
  {
    title: 'Synchronization with leading review platforms',
    values: [true, true, true],
    plansWith: [
      MembershipTypes.Basic,
      MembershipTypes.Essential,
      MembershipTypes.Professional,
    ],
  },
  {
    title: 'Review outreach/follow up via SMS/email',
    values: [true, true, true],
    plansWith: [
      MembershipTypes.Basic,
      MembershipTypes.Essential,
      MembershipTypes.Professional,
    ],
  },
  {
    title:
      'Guide customers to leaving reviews or non public feedback\n to help your business improve',
    values: [true, true, true],
    plansWith: [
      MembershipTypes.Basic,
      MembershipTypes.Essential,
      MembershipTypes.Professional,
    ],
  },
  {
    title: 'Manage and reply to all business reviews from one place',
    values: [true, true, true],
    plansWith: [
      MembershipTypes.Basic,
      MembershipTypes.Essential,
      MembershipTypes.Professional,
    ],
  },
  {
    title: 'Bulk import customers',
    values: [true, true, true],
    plansWith: [
      MembershipTypes.Basic,
      MembershipTypes.Essential,
      MembershipTypes.Professional,
    ],
  },
  {
    title: 'Customer profile management for organization',
    values: [true, true, true],
    plansWith: [
      MembershipTypes.Basic,
      MembershipTypes.Essential,
      MembershipTypes.Professional,
    ],
  },
  {
    title: 'Custom review outreach campaigns',
    values: [true, true, true],
    plansWith: [
      MembershipTypes.Basic,
      MembershipTypes.Essential,
      MembershipTypes.Professional,
    ],
  },
  {
    title: 'AI Assisted Customer Reviews',
    values: [false, true, true],
    plansWith: [MembershipTypes.Essential, MembershipTypes.Professional],
  },
  {
    title: 'AI Assisted Responses',
    values: [false, true, true],
    plansWith: [MembershipTypes.Essential, MembershipTypes.Professional],
  },
  {
    title: 'AI Assisted Review Outreach and Follow up',
    values: [false, true, true],
    plansWith: [MembershipTypes.Essential, MembershipTypes.Professional],
  },
  {
    title: 'CRM synchronization',
    values: [false, true, true],
    plansWith: [MembershipTypes.Essential, MembershipTypes.Professional],
  },
  {
    title: 'Basic review analytics',
    values: [false, true, true],
    plansWith: [MembershipTypes.Essential, MembershipTypes.Professional],
  },
  {
    title: 'Advanced review analytics',
    values: [false, false, true],
    plansWith: [MembershipTypes.Professional],
  },
  {
    title: '24/7 support line for Enterprise customers',
    values: [false, false, true],
    plansWith: [MembershipTypes.Professional],
  },
  {
    title: 'Desk Dedicated success agent',
    values: [false, false, true],
    plansWith: [MembershipTypes.Professional],
  },
];

export const sideDrawerItems: SideMenuItem[] = [
  {
    label: 'Onboarding',
    to: '/onboarding',
    Icon: OnboardingIcon,
  },
  {
    label: 'Dashboard',
    to: '/',
    Icon: DashboardIcon,
  },
  {
    label: 'Ratings',
    to: '/ratings',
    Icon: RatingsIcon,
  },
  {
    label: 'Customers',
    to: '/customers',
    Icon: CustomersIcon,
    children: [
      { label: 'Contacts', to: '/customers/contacts' },
      { label: 'Lists', to: '/customers/lists' },
    ],
  },
  {
    label: 'Analytics',
    to: '/analytics/ratings',
    Icon: AnalyticsIcon,
    children: [
      { label: 'Ratings', to: '/analytics/ratings' },
      { label: 'Campaigns', to: '/analytics/campaigns' },
    ],
  },
  {
    label: 'Campaigns',
    to: '/campaigns',
    Icon: CampaignsIcon,
  },
  {
    label: 'Automations',
    to: '/automations',
    Icon: AutomationsIcon,
  },
  {
    label: 'Integrations',
    to: '/integrations',
    Icon: IntegrationsIcon,
    children: [
      { label: 'Review platforms', to: '/integrations/reviews' },
      { label: 'CRM connection', to: '/integrations/crm' },
    ],
  },
];

export const ContactUsEmail = 'info@echoratings.com';

export const plansFeatures = plansAndBenefits.map((plan) => plan.title);

export function comparePlans(plan1: MembershipTypes, plan2: MembershipTypes) {
  return plansAndBenefits.map((benefit) => {
    const plan1Index = planNames.findIndex(
      (plan: { name: string }) => plan.name === plan1
    );
    const plan2Index = planNames.findIndex((plan) => plan.name === plan2);

    if (benefit.values[plan1Index] === benefit.values[plan2Index]) {
      return null;
    }
    return {
      title: benefit.title,
      plan1Value: plan1Index !== -1 ? benefit.values[plan1Index] : null,
      plan2Value: plan2Index !== -1 ? benefit.values[plan2Index] : null,
    };
  });
}
