import { useAppDispatch, useAppSelector } from '@app/redux/hooks';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export const AuthGuard = () => {
  const user = useAppSelector((state) => state.auth?.user);
  const business = useAppSelector((state) => state.business);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!business.selected) {
      const selectedBusiness = localStorage.getItem('selectedBusiness');
      if (selectedBusiness) {
        dispatch({
          type: 'business/selectBusiness',
          payload: { id: selectedBusiness },
        });
      } else {
        if (business?.list?.length === 1) {
          dispatch({
            type: 'business/selectBusiness',
            payload: { id: business.list[0].id },
          });
        }
        if (business?.list?.length > 1) {
          navigate('/select-business');
        }
        if (business?.list?.length === 0) {
          navigate('/select-business/create-new');
        }
      }
    }
  }, []);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  return <Outlet />;
};
