import { Button, HookedForm } from '@app/components';
import { FileDropField } from '@app/components/Fields/FileDropField';
import { useImportFromFilesPage } from './ImportFromFile.hooks';
import { downloadFile } from '@app/utils/utils';
import DocumentIcon from '@app/assets/icons/menu/document.svg?react';
import ArrowBackIcon from '@app/assets/icons/back.svg?react';

export const ImportFromFile = () => {
  const {
    importFileForm,
    uploadedFile,
    navigate,
    handleUpload,
    error,
    customerErrors,

    requiredColumns,
  } = useImportFromFilesPage();
  const fileUrl = new URL('/files/ECHO-sample.csv', import.meta.url).href;

  return (
    <div>
      <div className="bg-white border-b border-b-base-ghostWhite ">
        <div className="container mx-auto p-4 flex justify-between items-center">
          <h2 className="font-bold text-[28px]">
            <button
              onClick={() => navigate('/customers/contacts')}
              className="mr-2"
            >
              <ArrowBackIcon />
            </button>
            Import from file
          </h2>
          <div className="flex gap-2">
            <Button
              kind="secondary"
              leftNode={<DocumentIcon />}
              onClick={() => downloadFile(fileUrl, 'ECHO-sample.csv')}
              className="text-sm font-semibold"
            >
              Download .csv template
            </Button>
          </div>
        </div>
        <div className="container mx-auto px-4 pb-4 text-base-coolGray">
          <p>Select a file containing your customers to import </p>
        </div>
      </div>
      <div className="container mx-auto p-4">
        <HookedForm formHook={importFileForm} onSubmit={handleUpload}>
          <FileDropField
            disableConfirm={customerErrors && customerErrors.length > 0}
            allowedTypes={{ 'text/csv': ['.csv'] }}
            expectedColumns={requiredColumns}
          />
          {error && (
            <div className=" rounded-xl  bg-white  w-full flex items-start justify-between gap-2 p-4 mt-4 text-system-error">
              {error}
            </div>
          )}

          {customerErrors && customerErrors.length > 0 && (
            <div className="rounded-xl  bg-white  w-full flex items-start justify-between gap-2 p-4 mt-4 text-system-error">
              {customerErrors.map((customerError) => (
                <div key={customerError.index} className="flex gap-1">
                  <p className="font-bold text-sm">
                    <a href={`#row-${customerError.index}`}>
                      Row {customerError.index + 1}
                    </a>
                    , invalid fields:
                  </p>
                  <p className="text-base-davyGray text-sm">
                    {customerError.errors.join(', ')}
                  </p>
                </div>
              ))}
            </div>
          )}
        </HookedForm>
        {uploadedFile && (
          <div className="bg-white mt-4 p-4">
            <table className="border border-base-lavender border-collapse [&_tr]:border-b  w-full rounded-lg overfl">
              {uploadedFile?.data?.length > 0 && (
                <thead>
                  <tr className="border-collapse border-b  text-base-davyGray text-sm font-medium">
                    <th className="text-sm text-base-davyGray">#</th>
                    <th align="left" className="p-2">
                      First Name
                    </th>
                    <th align="left" className="p-2">
                      Last Name
                    </th>
                    <th align="left" className="p-2">
                      Email
                    </th>
                    <th align="left" className="p-2">
                      Phone Number
                    </th>
                    <th align="left" className="p-2">
                      Notes
                    </th>
                  </tr>
                </thead>
              )}
              <tbody>
                {(uploadedFile?.data as string[][])
                  ?.filter((customer) => {
                    if (customer.length === 5) {
                      return true;
                    }
                  })
                  .slice(1)
                  ?.map((value, index) => (
                    <tr id={`row-${index}`} key={index}>
                      <td
                        className="text-sm text-base-davyGray py-2"
                        valign="middle"
                        align="center"
                      >
                        {index}
                      </td>
                      {(value as string[])?.map(
                        (item: string, index: number) => (
                          <td key={index} className="p-2 text-sm">
                            {item}
                          </td>
                        )
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
