import { CreateListRequest } from '@app/models/requests';
import { apiInstance } from './api';
import { ApiRoutes } from './consts';

export const createList = async (data: CreateListRequest) => {
  return apiInstance.post(
    `${ApiRoutes.Business}${ApiRoutes.Lists}/create`,
    data
  );
};

export const getLists = async () => {
  return apiInstance.get(`${ApiRoutes.Business}${ApiRoutes.Lists}/`);
};

export const getList = async (id: string) => {
  return apiInstance.get(`${ApiRoutes.Business}${ApiRoutes.Lists}/${id}`);
};

export const getPaginatedLists = async (params?: {
  text?: string;
  pagination: { page: number; skip: number; limit: number };
}) => {
  return apiInstance.get(`${ApiRoutes.Business}${ApiRoutes.Lists}/paginated`, {
    params: {
      ...(params?.text && { text: params.text }),
      page: params?.pagination?.page ?? 1,
      skip: params?.pagination?.skip ?? 0,
      limit: params?.pagination?.limit ?? 10,
    },
  });
};

export const getListCustomers = async (
  id: string,
  params?: { pagination?: { page: number; skip: number; limit: number } }
) => {
  return apiInstance.get(
    `${ApiRoutes.Business}${ApiRoutes.Lists}/customers/${id}`,
    {
      params: {
        page: params?.pagination?.page ?? 1,
        skip: params?.pagination?.skip ?? 0,
        limit: params?.pagination?.limit ?? 10,
      },
    }
  );
};

export const getListWithCustomers = async (id: string) => {
  return apiInstance.get(
    `${ApiRoutes.Business}${ApiRoutes.Lists}/full-list/${id}`
  );
};

export const updateList = async (id: string, data: any) => {
  return apiInstance.put(`${ApiRoutes.Business}${ApiRoutes.Lists}/${id}`, data);
};
