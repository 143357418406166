import { CustomerInterface } from '@app/models/business';
import { deleteCustomers } from '@app/services/customers';
import { QueryObserverResult, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

export const useCustomerTable = (
  refetch?: () => Promise<QueryObserverResult<AxiosResponse<any, any>, Error>>
) => {
  const customerTableForm = useForm();
  const [deleteOpen, setDeleteOpen] = useState<CustomerInterface | undefined>();

  const handleDeleteCustomer = (customer?: CustomerInterface) => {
    setDeleteOpen(customer);
  };

  const { mutate: deleteCustomerFn, isPending: isPendingDelete } = useMutation({
    mutationKey: [`delete-customer`],
    mutationFn: (data: CustomerInterface) => deleteCustomers([data.id]),
    onSuccess: () => {
      toast.success('Customer deleted successfully');
      refetch && refetch();
      handleDeleteCustomer();
    },
    onError: (err) => {
      toast.error('Error deleting customer');
      console.error(err);
    },
  });

  return {
    customerTableForm,
    deleteCustomerFn,
    isPendingDelete,
    handleDeleteCustomer,
    deleteOpen,
  };
};
