import { FieldValues, useForm, UseFormReturn } from 'react-hook-form';
import { HookedForm } from '../HookedForm';
import { ListInterface, TagInterface } from '@app/models/util';
import { CheckSelectField } from '../Fields/CheckSelectField';

export const ContactFilters = ({
  filterForm,
  lists = [],
  tags = [],
}: {
  filterForm?: UseFormReturn<FieldValues>;
  lists?: ListInterface[];
  tags?: TagInterface[];
}) => {
  const formHook = useForm();
  return (
    <div>
      <HookedForm
        formHook={filterForm ?? formHook}
        mode="onChange"
        className="flex gap-4 flex-col md:flex-row"
      >
        <CheckSelectField
          name="source"
          options={[
            { label: 'All sources', value: 'all' },
            { label: 'ECHO', value: 'local' },
          ]}
          placeholder="Sources"
          sizeStyle="sm"
        />
        <CheckSelectField
          name="channelType"
          options={[
            { label: 'All channels', value: 'all' },
            { label: 'Email', value: 'email' },
            { label: 'Phone', value: 'phone' },
          ]}
          placeholder="Channels"
          sizeStyle="sm"
        />
        <CheckSelectField
          name="status"
          options={[
            { label: 'All statuses', value: 'all' },
            { label: 'No status', value: 'no-status' },
            { label: 'Campaign sent', value: 'campaign-sent' },
            { label: 'Reviewed', value: 'reviewed' },
            { label: 'Feedback', value: 'feedback' },
            { label: 'Unsubscribed', value: 'unsubscribed' },
          ]}
          placeholder="Statuses"
          sizeStyle="sm"
        />
        <CheckSelectField
          name="ratings"
          options={[
            { label: 'All ratings', value: 'all' },
            { label: '5 stars', value: '5' },
            { label: '4 stars', value: '4' },
            { label: '3 stars', value: '3' },
            { label: '2 stars', value: '2' },
            { label: '1 stars', value: '1' },
          ]}
          placeholder="Ratings"
          sizeStyle="sm"
        />
        <CheckSelectField
          name="platform"
          options={[
            { label: 'All platforms', value: 'all' },
            { label: 'Google', value: 'google' },
            { label: 'Facebook', value: 'facebook' },
            { label: 'Yelp', value: 'yelp' },
            { label: 'Trustpilot', value: 'trustpilot' },
          ]}
          placeholder="Platforms"
          sizeStyle="sm"
        />
        <CheckSelectField
          name="lists"
          options={[
            { label: 'All lists', value: 'all' },
            ...lists.map((list) => ({ label: list.name, value: list.id })),
          ]}
          placeholder="Lists"
          sizeStyle="sm"
        />
        <CheckSelectField
          name="tags"
          options={[
            { label: 'All tags', value: 'all' },
            ...tags.map((tag) => ({ label: tag.name, value: tag.id })),
          ]}
          placeholder="Tags"
          sizeStyle="sm"
        />
      </HookedForm>
    </div>
  );
};
