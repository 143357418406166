import clsx from 'clsx';
import {
  MembershipTypes,
  planNames,
  plansAndBenefits,
} from '@app/utils/consts';
import { formatPrice, getPlanButtonAndText } from '@app/utils/utils';
import { Button } from '../Button/Button';
import { PlanInterface } from '@app/models/plans';
import { AxiosResponse } from 'axios';
import CheckIcon from '@app/assets/icons/check.svg?react';
import CloseIcon from '@app/assets/icons/close.svg?react';

export const PlanUsageTable = ({
  plans,
  businessSubscription,
  handleSelectedPlan,
  isPending,
}: {
  businessSubscription: any;
  isPending: boolean;
  plans: AxiosResponse<any, any> | undefined;
  handleSelectedPlan: (plan: PlanInterface) => void;
}) => {
  return (
    <table className=" w-full">
      <thead>
        <tr>
          <th>&nbsp;</th>
          {(plans?.data as unknown as { plans: PlanInterface[] })?.plans?.map(
            (plan) => {
              const style = getPlanButtonAndText(
                businessSubscription?.plan as PlanInterface,
                plan
              );
              const info = planNames.find((p) => p.name === plan.type);

              return (
                <th key={plan.id} className="">
                  <div
                    className={clsx('bg-white', {
                      'rounded-t-xl mt-8':
                        plan.type !== MembershipTypes.Professional,
                    })}
                  >
                    {plan.type === MembershipTypes.Professional && (
                      <div className="text-xs bg-brand-baseBlue rounded-t-lg p-2 text-white text-center">
                        MOST POPULAR
                      </div>
                    )}
                    <div
                      key={plan.id}
                      className={clsx('  p-4 flex flex-col ', {
                        'rounded-t-xl mt-8':
                          plan.type !== MembershipTypes.Professional,
                      })}
                    >
                      <h4
                        className={`text-sm font-semibold flex gap-2 ${info?.class}`}
                      >
                        {info && <info.Icon className={info?.color} />}
                        <span className="capitalize">{plan.type}</span>
                      </h4>
                      <hr className="my-4" />
                      <div className="space-y-4 text-center">
                        <p>
                          <span className="text-3xl font-extrabold">
                            {formatPrice(plan.price)}
                          </span>
                          <span className="font-medium text-lg text-base-coolGray">
                            /m
                          </span>
                        </p>
                        <p className="text-base-coolGray/60">Billed monthly</p>
                        <div className="flex items-center justify-center">
                          <Button
                            kind={style.button ?? 'primary'}
                            disabled={style.disabled || isPending}
                            loading={isPending}
                            className="font-medium text-sm"
                            onClick={() => handleSelectedPlan(plan)}
                          >
                            {style.text}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
              );
            }
          )}
        </tr>
      </thead>
      <tbody className="bg-white">
        <tr>
          <td
            colSpan={4}
            className="text-left border-b border-b-base-ghostWhite p-4 font-bold"
          >
            Usage
          </td>
        </tr>
        {plansAndBenefits.map((benefit) => (
          <tr
            key={benefit.title}
            className="border-b border-b-base-ghostWhite "
          >
            <td className="p-4 text-brand-darkBlue2 font-medium text-sm">
              <span className="whitespace-pre-wrap">{benefit.title}</span>
            </td>
            {benefit.values.map((value, index) =>
              value ? (
                typeof value === 'boolean' ? (
                  <td
                    key={index}
                    align="center"
                    valign="middle"
                    className="p-2"
                  >
                    <div className="h-fit">
                      <CheckIcon width={20} height={20} />
                    </div>
                  </td>
                ) : (
                  <td
                    key={index}
                    align="center"
                    valign="middle"
                    className="p-2"
                  >
                    <span className="flex items-center w-fit">
                      <div className="h-fit ">
                        <CheckIcon width={20} height={20} />
                      </div>
                      {value}
                    </span>
                  </td>
                )
              ) : (
                <td key={index} align="center" valign="middle" className="p-2">
                  <div>
                    <CloseIcon width={20} height={20} />
                  </div>
                </td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
