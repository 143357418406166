import { Radio } from '@headlessui/react';
import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

interface RadioFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
  label: string;
}

export const RadioField = ({ label, value }: RadioFieldProps) => {
  const {
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  return (
    <label className="flex items-center gap-1">
      <Radio
        value={value}
        className="flex items-center justify-center group size-4 rounded-full border bg-white data-[checked]:border-2 data-[checked]:border-brand-baseBlue  data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 data-[checked]:data-[disabled]:bg-gray-500"
      >
        <div className="stroke-white size-2 opacity-0 group-data-[checked]:opacity-100 group-data-[checked]:bg-brand-baseBlue rounded-full"></div>
      </Radio>
      <span className="text-base-davyGray text-xs">{label}</span>
    </label>
  );
};
