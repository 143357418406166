import { PlanInterface } from '@app/models/plans';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authSlice } from './auth';
import { createAppAsyncThunk } from '../withTypes';
import { getPlans } from '@app/services/plans';

export interface AuthState {
  plans: PlanInterface | undefined;
}

const initialState: AuthState = {
  plans: undefined,
};

export const fetchPlans = createAppAsyncThunk('plan/fetchPlans', async () => {
  const response = await getPlans();
  return response?.data?.plans;
});

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    save: (state, action: PayloadAction<PlanInterface>) => {
      state.plans = action.payload;
    },
    update: (state, action: PayloadAction<PlanInterface>) => {
      state.plans = { ...state.plans, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authSlice.actions.logout, (state) => {
      state.plans = undefined;
    });
    builder.addCase(fetchPlans.fulfilled, (state, action) => {
      state.plans = action.payload;
    });
  },
});

export const { save, update } = planSlice.actions;

export default planSlice.reducer;
