import { ButtonHTMLAttributes } from 'react';
import { getButtonByKind } from './util';
import { Link } from 'react-router-dom';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: string;
  kind?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'danger';
  size?: 'default' | 'small';
  leftNode?: React.ReactNode;
  rightNode?: React.ReactNode;
  to?: string;
  loading?: boolean;
}
export const Button = ({
  children,
  kind,
  size,
  leftNode,
  rightNode,
  to,
  loading,
  ...rest
}: ButtonProps) => {
  const style = getButtonByKind(kind);

  if (to) {
    return (
      <Link to={to} className={`${style} ${rest.className ?? ''}`}>
        {leftNode && <span className="mr-2">{leftNode}</span>}
        {children && children}
        {rightNode && <span className="ml-2">{rightNode}</span>}
      </Link>
    );
  }
  return (
    <button {...rest} className={`${style} ${rest.className ?? ''} `}>
      {loading ? (
        <div className="flex items-center justify-center">
          <div className="w-5 h-5 border-2 border-t-white border-b-white rounded-full animate-spin" />
        </div>
      ) : (
        <>
          {leftNode && <span className="mr-2">{leftNode}</span>}
          {children && children}
          {rightNode && <span className="ml-2">{rightNode}</span>}
        </>
      )}
    </button>
  );
};
