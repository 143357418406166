import { SubscriptionInterface } from '@app/models/subscription';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import DocSearchIcon from '@app/assets/icons/doc-search.svg?react';
import IphoneIcon from '@app/assets/icons/iphone.svg?react';
import RefreshIcon from '@app/assets/icons/refresh.svg?react';
import DiamondIcon from '@app/assets/icons/diamond.svg?react';

export const PlanCard = ({
  businessSubscription,
}: {
  businessSubscription?: SubscriptionInterface;
}) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white p-4 h-fit">
      <div className="flex items-center justify-between">
        <h5 className="font-bold text-sm">Your current plan</h5>
        <button
          onClick={() => navigate('/settings/plan/details')}
          className="bg-base-antiFlash p-2 rounded-lg flex gap-2 items-center text-sm"
        >
          <span>View more</span>
          <DocSearchIcon />
        </button>
      </div>
      <hr className="my-4 border-base-ghostWhite" />
      <div className="w-full h-40 flex justify-between flex-col bg-gradient-to-r from-gradient-baseBlue from-[-15%]  to-gradient-ufoGreen to-[113%] rounded-xl text-white p-4">
        <p className="text-xs font-extrabold flex gap-2 items-center">
          <DiamondIcon />{' '}
          <span className="capitalize">{businessSubscription?.plan?.type}</span>
        </p>
        <p className="font-bold text-4xl text-brand-lightBlue4">
          ${businessSubscription?.plan?.price}
        </p>
        <p className="text-sm font-medium">Price per month</p>
      </div>
      <table className="my-4">
        <tbody>
          <tr>
            <td className="uppercase flex items-center gap-2 py-2 w-[160px]">
              <span className="bg-base-antiFlash p-1 rounded-md">
                <IphoneIcon />
              </span>
              <span className="text-base-coolGray font-medium text-xs">
                discount name
              </span>
            </td>
            <td>
              <span className="font-medium text-sm">
                {businessSubscription?.trialEnd && (
                  <>
                    Free trial - until{' '}
                    {DateTime.fromSeconds(
                      businessSubscription.trialEnd
                    ).toFormat('dd MMM, yyyy')}
                  </>
                )}
              </span>
            </td>
          </tr>
          <tr>
            <td className="uppercase flex items-center gap-2 py-2 w-[160px]">
              <span className="bg-base-antiFlash p-1 rounded-md">
                <RefreshIcon />
              </span>
              <span className="text-base-coolGray font-medium text-xs">
                next invoice
              </span>
            </td>
            <td>
              <span className="font-medium text-sm">
                around{' '}
                {DateTime.fromISO(businessSubscription?.endDate ?? '').toFormat(
                  'dd MMM, yyyy'
                )}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
