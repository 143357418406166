import { fetchUpdate } from '@app/redux/features/auth';
import { useAppDispatch, useAppSelector } from '@app/redux/hooks';
import { verifyPasswordEmail } from '@app/services/auth';
import {
  checkPhone,
  postUserPhoto,
  update,
  updateEmail,
  validatePhone,
} from '@app/services/user';
import { getObjectDiff } from '@app/utils/utils';
import {
  updatePasswordEmailValidation,
  updatePhoneValidation,
  updateProfileEmailValidation,
  updateProfilePhoneValidation,
  updateProfileValidation,
} from '@app/utils/validations';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const fieldsToIgnore = [
  'email',
  'isActivated',
  'id',
  'phoneNumber',
  'password',
  'activationLink',
  'resetPasswordExpires',
  'resetPasswordToken',
  'resetingConfirmed',
  'photo',
  'businesses',
  'subscriptions',
  'image',
  'stripeCustomerId',
];

export const useProfileSettingsPage = () => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [changePasswordSent, setChangePasswordSent] = useState(false);
  const [changeInformationOpen, setChangeInformationOpen] = useState(false);
  const [hasPhotoSelected, setHasPhotoSelected] = useState(false);
  const [showPhoneVerification, setShowPhoneVerification] = useState(false);
  const updateProfilePhotoForm = useForm();
  const updateProfileForm = useForm({ resolver: updateProfileValidation });
  const updateProfileEmailForm = useForm({
    resolver: updateProfileEmailValidation,
  });
  const updateProfilePhoneNumberForm = useForm({
    resolver: updateProfilePhoneValidation,
  });
  const changePasswordForm = useForm({
    resolver: updatePasswordEmailValidation,
  });
  const changePhoneForm = useForm({
    resolver: updatePhoneValidation,
  });
  const [error, setError] = useState<string | undefined>('');
  const { mutate: updateProfileFn, isPending: isLoading } = useMutation({
    mutationKey: ['update-profile'],
    mutationFn: (data: FieldValues) => update(data),
    onSuccess: () => {
      dispatch(fetchUpdate());
      handleChangeInformation();
      toast.success('Profile updated successfully');
    },
    onError: () => {
      setError('Error while updating profile.');
    },
  });

  const { mutate: updateEmailFn, isPending: isUpdatingEmail } = useMutation({
    mutationKey: ['update-email'],
    mutationFn: (data: FieldValues) => updateEmail({ email: data.email }),
    onSuccess: () => {
      dispatch(fetchUpdate());
      toast.success('Email updated successfully');
    },
    onError: (e: AxiosResponse) => {
      toast.error(e?.data?.error?.message ?? 'Error while updating email.');
    },
  });

  const submitEmailForm = (data: FieldValues) => {
    if (!data?.email) return;

    updateEmailFn(data);
  };

  const handleGotIt = () => {
    setChangePasswordOpen(false);
    setChangePasswordSent(false);
  };

  const sentPasswordChangeRequest = (values: FieldValues) => {
    if (!values?.email) return;
    verifyPasswordEmail(values.email)
      .then(() => setChangePasswordSent(true))
      .catch((err) => console.error(err));
  };
  const handleChangeInformation = () => {
    setChangeInformationOpen(!changeInformationOpen);
  };

  const handlePhotoSaveForm = async (values: FieldValues) => {
    if (values?.photo?.[0]?.name) {
      await postUserPhoto(values.photo?.[0]);
      handleChangeInformation();
      dispatch(fetchUpdate());
    }
  };

  const handleUpdateForm = (values: FieldValues) => {
    const originalData = { ...authState?.user };

    const differentKeys = getObjectDiff({ ...values }, originalData).filter(
      (key) => !fieldsToIgnore.includes(key)
    );

    if (differentKeys?.length) {
      const newData = differentKeys.map((key) => {
        return { [key]: values[key] };
      });

      const data = Object.assign({}, ...newData);

      if (Object.keys(data).length > 0) {
        updateProfileFn(data);
      }
    }
  };

  const handlePhoneNumberModal = () => {
    setShowPhoneVerification(!showPhoneVerification);
  };

  const handlePhoneUpdate = (values: FieldValues) => {
    checkPhone({ phoneNumber: values.phoneNumber })
      .then(() => {
        handlePhoneNumberModal();
      })
      .catch((err) => {
        updateProfilePhoneNumberForm.setError('phoneNumber', {
          message:
            err?.data?.error?.message ?? 'Error while updating phone number.',
        });
        console.error(err);
      });
  };

  const handlePhoneVerification = (values: FieldValues) => {
    const phoneNumber = updateProfilePhoneNumberForm.getValues('phoneNumber');
    validatePhone({ code: values.code, phone: phoneNumber })
      .then(() => {
        toast.success('Phone number validated and saved.');
        handlePhoneNumberModal();
        dispatch(fetchUpdate());
      })
      .catch((err) => {
        changePhoneForm.setError('code', {
          message: err?.data?.error?.message ?? 'Error while validating code.',
        });
        console.error(err);
      });
  };

  const profileFormIsDirty = updateProfileForm.formState.isDirty;
  const photoFormIsDirty = updateProfilePhotoForm.formState.isDirty;

  useEffect(() => {
    if (photoFormIsDirty) {
      setHasPhotoSelected(true);
    }
  }, [photoFormIsDirty]);

  return {
    changeInformationOpen,
    handleChangeInformation,
    changePasswordOpen,
    changePasswordSent,
    handleGotIt,
    setChangePasswordOpen,
    sentPasswordChangeRequest,
    authState,
    updateProfileForm,
    handleUpdateForm,
    changePasswordForm,
    error,
    isLoading,
    updateProfilePhotoForm,
    handlePhotoSaveForm,
    profileFormIsDirty,
    photoFormIsDirty,
    hasPhotoSelected,
    updateProfileEmailForm,
    submitEmailForm,
    isUpdatingEmail,
    updateProfilePhoneNumberForm,
    handlePhoneNumberModal,
    handlePhoneUpdate,
    showPhoneVerification,
    handlePhoneVerification,
    changePhoneForm,
  };
};
