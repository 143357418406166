import Logo from '@app/assets/horizontal-logo.svg?react';
import { useValidateEmailPage } from './ValidateEmail.hooks';

export const ValidateEmail = () => {
  const { validationError } = useValidateEmailPage();
  return (
    <div className="flex items-center justify-center flex-col min-h-screen py-6">
      <Logo width={139} height={110} className="mb-auto" />
      <div className="bg-white text-center space-y-4 rounded max-w-[510px] px-10 pt-10 pb-6 mb-auto">
        <h2 className="font-bold text-3xl">Validating Email</h2>
        <p className="text-base-coolGray ">
          Your email is being validated and you will be redirected to the login
          page shortly.
        </p>
        {validationError && (
          <div className="bg-system-errorLight p-2 rounded-lg text-system-error font-medium ">
            <p>{validationError}</p>
          </div>
        )}
      </div>
    </div>
  );
};
