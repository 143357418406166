import { Button } from '@app/components';
import Logo from '@app/assets/horizontal-logo.svg?react';
import AIIcon from '@app/assets/icons/AI.svg?react';
import AnalyticsIcon from '@app/assets/icons/analytics.svg?react';
import AutomationIcon from '@app/assets/icons/automation.svg?react';
import BulkIcon from '@app/assets/icons/bulk-import.svg?react';
import HeadphonesIcon from '@app/assets/icons/headphones.svg?react';
import OpenMailIcon from '@app/assets/icons/open-mail.svg?react';
import SeatsIcon from '@app/assets/icons/seats.svg?react';
import StarIcon from '@app/assets/icons/star.svg?react';
import { useWelcomePage } from './Welcome.hooks';

export const Welcome = () => {
  const { navigate } = useWelcomePage();
  return (
    <div className="flex items-center justify-center flex-col min-h-screen py-6">
      <Logo width={139} height={110} className="mb-auto" />
      <div className="bg-white text-center space-y-4 rounded max-w-[720px] px-10 pt-10 pb-6 mb-auto">
        <h2 className="font-bold text-3xl">
          Welcome to your free 14-day trial
        </h2>
        <p className="text-base-coolGray ">
          You have been upgraded to the free trial of the EchoRatings Essential
          Plan! Explore our premium features while on trial, after which you can
          upgrade to the premium plan at any time via the settings.
        </p>
        <p className="font-bold my-8">Paid features to explore</p>
        <div className=" ">
          <div>
            <ul className="grid grid-cols-1 md:grid-cols-2 text-left gap-6 gap-x-8 flex-col">
              <li className="flex gap-2 flex-row">
                <div>
                  <OpenMailIcon />
                </div>
                <div>
                  <h4 className="text-sm mb-2 font-semibold">Campaigns</h4>
                  <p className="text-xs text-base-coolGray">
                    Create simple and automated review request campaigns for
                    your customers
                  </p>
                </div>
              </li>
              <li className="flex gap-2 flex-row">
                <div>
                  <SeatsIcon />
                </div>
                <div>
                  <h4 className="text-sm mb-2 font-semibold">Member seats</h4>
                  <p className="text-xs text-base-coolGray">
                    Multiple seat options for different tier levels
                  </p>
                </div>
              </li>
              <li className="flex gap-2 flex-row">
                <div>
                  <BulkIcon />
                </div>
                <div>
                  <h4 className="text-sm mb-2 font-semibold">
                    Bulk and manual import customers
                  </h4>
                  <p className="text-xs text-base-coolGray">
                    Customer import and CRM ability to enter in previous/past
                    customers
                  </p>
                </div>
              </li>
              <li className="flex gap-2 flex-row">
                <div>
                  <AIIcon />
                </div>
                <div>
                  <h4 className="text-sm mb-2 font-semibold">
                    AI Assisted Reviews for stronger feedback
                  </h4>
                  <p className="text-xs text-base-coolGray">
                    AI will suggest (based on line of work) to the customer what
                    to write for their review when these review link is sent.
                  </p>
                </div>
              </li>
              <li className="flex gap-2 flex-row">
                <div>
                  <HeadphonesIcon />
                </div>
                <div>
                  <h4 className="text-sm mb-2 font-semibold">
                    Industry-leading support
                  </h4>
                  <p className="text-xs text-base-coolGray">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                </div>
              </li>

              <li className="flex gap-2 flex-row">
                <div>
                  <AutomationIcon />
                </div>
                <div>
                  <h4 className="text-sm mb-2 font-semibold">Automations</h4>
                  <p className="text-xs text-base-coolGray">
                    Automatic review follow-up via email/text
                  </p>
                </div>
              </li>
              <li className="flex gap-2 flex-row">
                <div>
                  <StarIcon />
                </div>
                <div>
                  <h4 className="text-sm mb-2 font-semibold">
                    Leading review platforms
                  </h4>
                  <p className="text-xs text-base-coolGray">
                    Connections with leading review platforms
                  </p>
                </div>
              </li>
              <li className="flex gap-2 flex-row">
                <div>
                  <AnalyticsIcon />
                </div>
                <div>
                  <h4 className="text-sm mb-2 font-semibold">Analytics</h4>
                  <p className="text-xs text-base-coolGray">
                    In-depth, structured analytics for detailed review
                    monitoring
                  </p>
                </div>
              </li>
              <li className="flex gap-2 flex-row">
                <div>
                  <AIIcon />
                </div>
                <div>
                  <h4 className="text-sm mb-2 font-semibold">
                    AI Assisted email follow up and customization
                  </h4>
                  <p className="text-xs text-base-coolGray">
                    Implementing AI to tailor and personalize emails, including
                    crafting follow-ups and expressions of gratitude.
                  </p>
                </div>
              </li>
              <li className="flex gap-2 flex-row">
                <div>
                  <HeadphonesIcon />
                </div>
                <div>
                  <h4 className="text-sm mb-2 font-semibold">
                    Ongoing support
                  </h4>
                  <p className="text-xs text-base-coolGray">
                    24/7 support line for Enterprise customers
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <Button onClick={() => navigate('/')} className="w-full">
          Start 14-day free trial
        </Button>
      </div>
    </div>
  );
};
