import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import RefreshIcon from '@app/assets/icons/refresh.svg?react';
import DisconnectIcon from '@app/assets/icons/unlink.svg?react';
import HorizontalDotsIcon from '@app/assets/icons/dot-horizontal.svg?react';

export const IntegrationMenu = ({
  deleteAction,
  updateAction,
}: {
  id: string;
  updateAction?: () => void;
  deleteAction?: () => void;
}) => {
  return (
    <Menu>
      <MenuButton className="group/user flex items-center justify-center gap-2">
        <HorizontalDotsIcon />
      </MenuButton>
      <MenuItems
        transition
        anchor="left start"
        className="p-2 bg-white shadow-lg mt-2 min-w-[220px] transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 rounded-lg border file:border-base-antiFlash"
      >
        <MenuItem>
          <button
            className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4 text-xs w-full"
            {...(updateAction && { onClick: updateAction })}
          >
            <RefreshIcon className="[&_path]:stroke-brand-darkBlue3" /> Change
            account
          </button>
        </MenuItem>
        <MenuItem>
          <button
            className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4 text-xs w-full"
            {...(deleteAction && { onClick: deleteAction })}
          >
            <DisconnectIcon className="[&_path]:stroke-system-error" />{' '}
            Disconnect
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
};
