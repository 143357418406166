import { CustomerInterface } from '@app/models/business';
import { CheckboxField } from '../Fields/CheckboxField';
import { useNavigate } from 'react-router-dom';
import { ListPill, TagPill } from './CustomerTable';
import { CustomerMenu } from '../CustomerMenu/CustomerMenu';
import UserIcon from '@app/assets/icons/user.svg?react';
import PhoneIcon from '@app/assets/icons/iphone.svg?react';
import MailIcon from '@app/assets/icons/email.svg?react';
import { CustomerStatus, CustomerStatusMap } from '@app/utils/consts';

export const CustomerTableRow = ({
  customer,
  addToListAction,
  scheduleCampaignAction,
  deleteCustomerAction,
  handleCheckbox,
  simplified,
  checked,
}: {
  customer: CustomerInterface;
  addToListAction?: () => void;
  scheduleCampaignAction?: () => void;
  deleteCustomerAction?: (customer: CustomerInterface) => void;
  handleCheckbox?: (customer?: string) => void;
  simplified?: boolean;
  checked?: boolean;
}) => {
  const navigate = useNavigate();

  const goToProfile = () => {
    navigate(`/customers/contacts/${customer.id}`);
  };

  const status = CustomerStatusMap.get(
    (String(customer.status) ?? 'null') as CustomerStatus
  );

  return (
    <tr
      key={customer.id}
      className="border-b border-base-antiFlash last-of-type:border-b-0 text-xs cursor-pointer hover:bg-brand-lightBlue4"
    >
      <td className="p-4 min-w-fit w-0">
        <CheckboxField
          checked={checked}
          name={`customers.[${customer.id}]`}
          value={customer.id}
          onCheckMark={handleCheckbox}
        />
      </td>
      <td className="p-4" onClick={goToProfile} valign="top">
        <div className="flex items-center gap-1 justify-start">
          <div className="flex items-center justify-center w-6 h-6 rounded-sm p-1 bg-base-antiFlash">
            <UserIcon />
          </div>
          <span>
            {customer.firstName} {customer.lastName}
          </span>
        </div>
      </td>
      <td className="p-4" onClick={goToProfile}>
        <div className="space-y-1">
          {customer.email && (
            <div className="flex gap-1 items-center">
              <div className="flex items-center justify-center w-6 h-6 rounded-sm p-1 bg-base-antiFlash">
                <MailIcon />
              </div>
              <span>{customer.email}</span>
            </div>
          )}
          {customer.phoneNumber && (
            <div className="flex gap-1 items-center">
              <div className="flex items-center justify-center w-6 h-6 rounded-sm p-1 bg-base-antiFlash">
                <PhoneIcon />
              </div>
              <span>{customer.phoneNumber}</span>
            </div>
          )}
        </div>
      </td>
      {!simplified && (
        <>
          <td className="p-4">
            <div className="flex gap-2">
              {customer?.lists?.map((list) => (
                <ListPill key={list.id} name={list.name} />
              ))}
            </div>
          </td>
          <td className="p-4 ">
            <div className="flex gap-2">
              {customer?.tags?.map((value) => (
                <TagPill key={value.id} name={value.name} />
              ))}
            </div>
          </td>
        </>
      )}
      <td className="p-4" onClick={goToProfile}>
        <span
          className={`w-2 h-2 inline-block rounded-full ${status?.color} mr-1`}
        ></span>
        <span>{status?.label}</span>
      </td>
      <td className="p-4" onClick={goToProfile}>
        -
      </td>
      <td className="p-4">
        <CustomerMenu
          simplified={simplified}
          id={customer.id}
          addToListAction={() => {
            addToListAction && addToListAction();
          }}
          deleteCustomerAction={() => {
            deleteCustomerAction && deleteCustomerAction(customer);
          }}
          scheduleCampaignAction={() => {
            scheduleCampaignAction && scheduleCampaignAction();
          }}
        />
      </td>
    </tr>
  );
};
