import { getLastTwelveMonths } from '@app/utils/utils';
import { Chart } from 'react-google-charts';

const dataDummy = [
  ['Months', '5-star', '4-star', '3-star', '2-star', '1-star'],
  ...getLastTwelveMonths().map((month) => [
    month,
    Math.floor(Math.random() * 100),
    Math.floor(Math.random() * 100),
    Math.floor(Math.random() * 100),
    Math.floor(Math.random() * 100),
    Math.floor(Math.random() * 100),
  ]),
];

export const ColumnChart = ({
  inverted,
  hideLegend,
  data = dataDummy,
}: {
  inverted?: boolean;
  hideLegend?: boolean;
  data?: (string | number)[][];
}) => {
  return (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="400px"
      data={data}
      options={{
        isStacked: true,
        legend: {
          position: hideLegend ? 'none' : 'bottom',

          textStyle: {
            fontName: 'Plus Jakarta Sans',
          },
        },
        vAxis: {
          textStyle: {
            fontName: 'Plus Jakarta Sans',
            fontSize: '12',
            color: 'rgba(0, 0, 0, 0.64)',
          },
          direction: inverted ? -1 : 1,
        },
        hAxis: {
          textStyle: {
            fontName: 'Plus Jakarta Sans',
            fontSize: '12',
            color: 'rgba(0, 0, 0, 0.64)',
          },

          gridlines: {
            color: 'transparent',
          },
        },
        colors: ['#1E4A8B', '#3279C5', '#52B6FE', '#A8DBFF', '#CBE9FF'],
      }}
    />
  );
};
