import {
  Checkbox,
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import { SelectHTMLAttributes, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ChevronDownIcon from '@app/assets/icons/chevron-down.svg';
import clsx from 'clsx';
import { ListPill } from '../CustomerTable/CustomerTable';

interface SelectFieldProps extends SelectHTMLAttributes<HTMLSelectElement> {
  placeholder?: string;
  options: { value: string; label: string }[];
  showDefaultOption?: boolean;
  sizeStyle?: 'sm' | 'md' | 'lg';
  label?: string;
  isOptional?: boolean;
  showPill?: boolean;
}

const getStyle = (size: string) => {
  switch (size) {
    case 'sm': {
      return 'text-sm py-2 px-3';
    }
    case 'md': {
      return 'text-normal py-3 px-4';
    }
    case 'lg': {
      return 'text-lg py-4 px-5';
    }
  }
};

export const CheckSelectField = ({
  name,
  placeholder,
  options,
  showDefaultOption = false,
  sizeStyle = 'md',
  label,
  isOptional,
  showPill,
  ...rest
}: SelectFieldProps) => {
  const {
    control,
    getValues,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState<string[]>([]);

  const fieldValues = getValues(name ?? '');

  useEffect(() => {
    if (fieldValues && fieldValues.length > 0) {
      setSelected(fieldValues);
    }
  }, []);

  return (
    <Controller
      control={control}
      name={name ?? ''}
      defaultValue={rest.defaultValue}
      render={({ field: { onChange, value } }) => {
        return (
          <div className="w-full">
            <Combobox
              multiple
              immediate
              value={value}
              onChange={(event) => {
                onChange(event);
                setSelected(event);
              }}
              onClose={() => setQuery('')}
            >
              {() => (
                <>
                  {label && (
                    <label
                      htmlFor={name}
                      className="font-medium text-sm inline-block mb-1"
                    >
                      {label}{' '}
                      {isOptional && (
                        <span className="text-base-coolGray">(Optional)</span>
                      )}
                    </label>
                  )}
                  <div className="relative w-full">
                    <ComboboxInput
                      aria-label={name}
                      onChange={(event) => setQuery(event.target.value ?? '')}
                      placeholder={placeholder}
                      className={clsx(
                        `group bg-base-seasalt rounded py-3 px-4 w-full outline-brand-baseBlue outline-1 ${rest.className ?? ''} ${getStyle(sizeStyle)}`,
                        { 'border border-system-error': name && errors[name] }
                      )}
                      style={{
                        backgroundImage: `url(${ChevronDownIcon})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right center',
                        backgroundSize: '1.5rem',
                      }}
                    />
                  </div>
                  <ComboboxOptions
                    anchor="bottom"
                    className="min-w-[--input-width] rounded-lg shadow-lg bg-white mt-2 !max-h-[400px] "
                  >
                    {showDefaultOption && (
                      <option
                        value="null"
                        disabled
                        className="data-[focus]:bg-brand-lightBlue4 p-4 text-sm border-b"
                      >
                        Choose an option
                      </option>
                    )}
                    {query.length > 0 &&
                      options
                        .filter((option) =>
                          option?.label
                            ?.toLowerCase()
                            .includes(query.toLowerCase())
                        )
                        .map((option) => (
                          <ComboboxOption
                            key={option.value}
                            value={option.value}
                            className="data-[focus]:bg-brand-lightBlue4 p-4 text-sm border-b border-b-base-seasalt"
                          >
                            {({ selected }) => (
                              <div className="flex justify-between items-center gap-2">
                                <span className={selected ? 'font-bold' : ''}>
                                  {option.label}
                                </span>
                                <div>
                                  <Checkbox
                                    checked={selected}
                                    className="group block size-4 rounded border bg-white data-[checked]:bg-brand-baseBlue data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 data-[checked]:data-[disabled]:bg-gray-500"
                                  >
                                    <svg
                                      className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                    >
                                      <path
                                        d="M3 8L6 11L11 3.5"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </Checkbox>
                                </div>
                              </div>
                            )}
                          </ComboboxOption>
                        ))}
                    {(!query || query.length === 0) &&
                      options.map((option) => (
                        <ComboboxOption
                          key={option.value}
                          value={option.value}
                          className="data-[focus]:bg-brand-lightBlue4 p-4 text-sm border-b border-b-base-seasalt"
                        >
                          {({ selected }) => (
                            <div className="flex justify-between items-center gap-2">
                              <span className={selected ? 'font-bold' : ''}>
                                {option.label}
                              </span>
                              <div>
                                <Checkbox
                                  checked={selected}
                                  className="group block size-4 rounded data-[checked]:border-brand-baseBlue border border-base-frenchGray bg-white data-[checked]:bg-brand-baseBlue data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 data-[checked]:data-[disabled]:bg-gray-500"
                                >
                                  <svg
                                    className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                  >
                                    <path
                                      d="M3 8L6 11L11 3.5"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </Checkbox>
                              </div>
                            </div>
                          )}
                        </ComboboxOption>
                      ))}
                  </ComboboxOptions>
                </>
              )}
            </Combobox>
            {showPill && selected?.length > 0 && (
              <div className="flex gap-2 mt-2 flex-wrap">
                {selected.map((selected, index) => {
                  const label = options.find(
                    (opt) => opt.value === selected
                  )?.label;
                  return <ListPill key={index} name={label ?? ''} />;
                })}
              </div>
            )}
            {name && errors[name] && (
              <p role="alert" className="text-system-error">
                <p
                  role="alert"
                  className="text-system-error text-sm text-left pt-1"
                >
                  {typeof errors?.[name]?.message === 'string'
                    ? errors[name]?.message
                    : ''}
                </p>
              </p>
            )}
          </div>
        );
      }}
    />
  );
};
