import {
  getLastTwelveMonths,
  groupReviewsByRatingAndMonth,
} from '@app/utils/utils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useAnalyticsRatings = () => {
  const navigate = useNavigate();
  const [chartData, setChartData] = useState<(string | number)[][]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // @ts-ignore - will use this implementation next
  const buildChartData = () => {
    const groupedReviews: { [key: string]: { [rating: number]: number } } =
      groupReviewsByRatingAndMonth([]);
    const lastTwelveMonths = getLastTwelveMonths();

    setChartData([
      ['Months', '5-star', '4-star', '3-star', '2-star', '1-star'],
      ...lastTwelveMonths.map((month) => {
        return [
          month,
          groupedReviews[month]?.[5] || 0,
          groupedReviews[month]?.[4] || 0,
          groupedReviews[month]?.[3] || 0,
          groupedReviews[month]?.[2] || 0,
          groupedReviews[month]?.[1] || 0,
        ];
      }),
    ]);
  };

  return { chartData, navigate };
};
