import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { BaseDialog } from '../BaseDialog/BaseDialog';
import { Button } from '../Button/Button';
import NoPhoto from '@app/assets/icons/menu/no-photo.svg?react';
import BulbIcon from '@app/assets/icons/menu/bulb.svg?react';
import NotificationIcon from '@app/assets/icons/menu/notification.svg?react';
import DocumentIcon from '@app/assets/icons/menu/document.svg?react';
import InfoIcon from '@app/assets/icons/menu/info.svg?react';
import LogoutIcon from '@app/assets/icons/menu/logout.svg?react';
import UserIcon from '@app/assets/icons/menu/user.svg?react';
import ChevronDownIcon from '@app/assets/icons/chevron-down.svg?react';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/redux/hooks';

export const UserMenu = () => {
  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [logoutOpen, setLogoutOpen] = useState(false);

  return (
    <>
      <Menu>
        <MenuButton className="group/user flex items-center justify-center gap-2">
          {authState?.user?.photo?.url ? (
            <div className="h-8 w-8 rounded-full overflow-hidden relative">
              <img
                src={authState?.user?.photo?.url}
                className="h-full w-full absolute object-cover object-center"
              />
            </div>
          ) : (
            <NoPhoto />
          )}
          <span className="hidden md:inline-block">
            {authState?.user?.firstName} {authState?.user?.lastName}
          </span>
          <ChevronDownIcon className="transition ease-in-out transform group-data-[active]/user:rotate-180" />
        </MenuButton>
        <MenuItems
          transition
          anchor="bottom"
          className="p-2 bg-white shadow-md mt-2 min-w-[220px] transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
        >
          <MenuItem>
            <div className="flex gap-2 items-start">
              <div className="flex flex-col px-2">
                <span>
                  {authState?.user?.firstName} {authState?.user?.lastName}
                </span>
                <small className="text-base-coolGray">
                  {authState?.user?.email}
                </small>
              </div>
            </div>
          </MenuItem>
          <hr className="my-2" />
          <MenuItem>
            <Link
              className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4"
              to="/profile"
            >
              <UserIcon /> Profile settings
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4"
              to="/support"
            >
              <NotificationIcon /> Notification settings
            </Link>
          </MenuItem>
          <hr className="my-2" />
          <MenuItem>
            <Link
              className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4"
              to="/license"
            >
              <BulbIcon /> Support & FAQ
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4"
              to="/license"
            >
              <DocumentIcon /> Documentation
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4"
              to="/license"
            >
              <InfoIcon /> Leave feedback
            </Link>
          </MenuItem>
          <MenuItem>
            <button
              className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4"
              onClick={() => setLogoutOpen(true)}
            >
              <LogoutIcon /> Log out
            </button>
          </MenuItem>
        </MenuItems>
      </Menu>
      <BaseDialog
        size="max-w-lg"
        open={logoutOpen}
        onClose={() => setLogoutOpen(false)}
      >
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-center">
          <div className="bg-system-errorLight flex items-center p-1 rounded-lg">
            <LogoutIcon className="ml-1" width={24} height={24} />
          </div>
          <h5 className="font-bold text-lg">Logout</h5>
        </div>
        <div className="px-6 ">
          <p className="text-sm">
            Are you sure you want to log out of your account?
          </p>
        </div>
        <div className="flex items-center gap-4 justify-center p-6">
          <Button className="w-full" onClick={() => setLogoutOpen(false)}>
            Cancel
          </Button>
          <Button
            className="w-full bg-system-error"
            onClick={() => {
              setLogoutOpen(false);
              dispatch({ type: 'auth/logout' });
            }}
          >
            Log out
          </Button>
        </div>
      </BaseDialog>
    </>
  );
};
