import { Chart, GoogleChartOptions } from 'react-google-charts';

export const data = [
  ['Task', 'Hours per Day'],
  ['Google', 11],
  ['Facebook', 2],
  ['Yelp', 2],
  ['Trustpilot', 2],
];

export const options: Partial<GoogleChartOptions> = {
  pieHole: 0.4,
  is3D: false,
  legend: {
    position: 'right',
    alignment: 'center',
  },
  allowHtml: true,
};

export const DonutChart = ({ opt }: { opt?: Partial<GoogleChartOptions> }) => {
  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height={'100%'}
      data={data}
      options={{ options, ...opt }}
      legendToggle={false}
    />
  );
};
