import { useAppSelector } from '@app/redux/hooks';
import { getBusinessInfo } from '@app/services/business';
import { useQuery } from '@tanstack/react-query';

export const useDashboardHook = () => {
  const businessState = useAppSelector((state) => state.business);
  const { data: businessInfo, isLoading } = useQuery({
    queryKey: ['business-info'],
    queryFn: () =>
      getBusinessInfo()
        .then((res) => res.data)
        .catch((err) => console.error(err)),
  });
  return { businessInfo, isLoading, businessState };
};
