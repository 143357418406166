import DotsIcon from '@app/assets/icons/menu/dot-vertical.svg?react';
import { CheckboxField } from '../Fields/CheckboxField';
import { HookedForm } from '../HookedForm';
import { useNavigate } from 'react-router-dom';
import { ListInterface } from '@app/models/util';
import { CustomerInterface } from '@app/models/business';
import { DateTime } from 'luxon';
import UsersIcon from '@app/assets/icons/grid/users.svg?react';
import FolderIcon from '@app/assets/icons/folder.svg?react';
import { Pagination } from '../Pagination/Pagination';

const tableHeader = [
  { label: 'LIST', field: 'name' },
  { label: 'ID', field: 'id' },
  { label: 'CUSTOMERS', field: 'customers' },
  { label: 'CREATION DATE', field: 'createdAt' },
];

interface ListTableInterface {
  lists: ListInterface[];
  handleNextPage: (value: number) => void;
  handlePrevPage: (value: number) => void;
  handleLimit: (value: number) => void;
  total: number;
  page: number;
}

export const ListsTable = ({
  lists,
  handleNextPage,
  handlePrevPage,
  handleLimit,
  total,
  page,
}: ListTableInterface) => {
  const navigate = useNavigate();
  return (
    <div className="bg-white overflow-x-auto">
      <HookedForm>
        <table className="w-full">
          <thead>
            <tr className="border-b border-base-antiFlash">
              <th className="p-4 min-w-fit w-0">
                <CheckboxField name="lists" value="all" />
              </th>
              {tableHeader.map((item, index) => (
                <th
                  key={index}
                  className="p-4 text-xs font-medium text-left text-base-coolGray"
                >
                  {item.label}
                </th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {lists?.map((list) => {
              return (
                <tr
                  onClick={() => navigate(`/customers/lists/${list.id}`)}
                  key={list.id}
                  className="border-b border-base-antiFlash last-of-type:border-b-0 text-xs cursor-pointer hover:bg-brand-lightBlue3"
                >
                  <td className="p-4 min-w-fit w-0">
                    <CheckboxField name="id" value={list.id} />
                  </td>
                  <td className="p-4 flex items-center gap-2">
                    <div className="bg-base-antiFlash rounded-sm size-6 flex items-center justify-center">
                      <FolderIcon />
                    </div>
                    {list.name}
                  </td>
                  <td className="p-4 text-base-davyGray">#{list.id}</td>
                  <td className="p-4">
                    <div className="flex items-center gap-2">
                      {list &&
                        list?.customers &&
                        list?.customers?.length > 0 && (
                          <>
                            <span className="p-1 inline-flex bg-base-antiFlash rounded-md">
                              <UsersIcon width={15} height={15} />
                            </span>
                            <div className="inline-flex items-center gap-2">
                              {list?.customers
                                ?.slice(0, 3)
                                ?.map(
                                  (customer: CustomerInterface) =>
                                    `${customer.firstName} ${customer.lastName}`
                                )
                                .join(', ')}
                              {list?.customers &&
                                list?.customers?.length > 3 && (
                                  <span className="w-fit px-1 h-5 items-center justify-center inline-flex bg-brand-lightBlue1 rounded-md text-xs font-bold text-brand-darkBlue3">
                                    + {list.customers?.length - 3}
                                  </span>
                                )}
                            </div>
                          </>
                        )}
                      {list &&
                        list?.customers &&
                        list?.customers?.length === 0 && (
                          <span>No customer added to list.</span>
                        )}
                    </div>
                  </td>
                  <td className="p-4 text-base-davyGray">
                    {DateTime.fromISO(list.createdAt).toFormat(
                      'LLL dd, yyyy  HH:MM'
                    )}
                  </td>
                  <td className="p-4">
                    <button>
                      <DotsIcon />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          handleLimitChange={handleLimit}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          page={page}
          total={total}
        />
      </HookedForm>
    </div>
  );
};
