import { verifyPasswordEmail } from '@app/services/auth';
import { forgotPasswordValidation } from '@app/utils/validations';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const useForgotPasswordPage = () => {
  const forgotPassordForm = useForm({ resolver: forgotPasswordValidation });
  const [emailSent, setEmailSent] = useState(false);
  const [wasResent, setWasResent] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const navigate = useNavigate();

  const userEmail = forgotPassordForm.watch('email');

  const handleSendReset = () => {
    setEmailSent(true);
  };

  const submitForgotPassword = async (values: FieldValues) => {
    setErrorMessage(undefined);
    verifyPasswordEmail(values.email)
      .then(() => handleSendReset())
      .catch((err) => {
        setErrorMessage(
          err?.data?.error?.message ?? 'Error while sending request'
        );
      });
  };

  const resendLink = () => {
    setErrorMessage(undefined);
    verifyPasswordEmail(userEmail)
      .then(() => {
        setWasResent(true);
        setDisableResend(true);
        setTimeout(() => {
          setDisableResend(false);
        }, 60 * 1000);
      })
      .catch((err) => {
        setErrorMessage(
          err?.data?.error?.message ?? 'Error while resending request'
        );
      });
  };

  return {
    forgotPassordForm,
    submitForgotPassword,
    emailSent,
    navigate,
    userEmail,
    resendLink,
    wasResent,
    disableResend,
    errorMessage,
  };
};
