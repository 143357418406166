import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';

interface BaseDialogProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
  size?:
    | 'max-w-sm'
    | 'max-w-md'
    | 'max-w-lg'
    | 'max-w-xl'
    | 'max-w-2xl'
    | 'max-w-3xl'
    | 'max-w-4xl';
}
export const BaseDialog = ({
  open,
  onClose,
  children,
  size = 'max-w-lg',
}: BaseDialogProps) => {
  return (
    <Dialog
      transition
      open={open}
      onClose={onClose}
      className="fixed inset-0 flex w-screen items-center justify-center p-4 transition duration-300 ease-out data-[closed]:opacity-0"
    >
      <DialogBackdrop className="fixed inset-0 bg-black/15" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className={`space-y-4 border bg-white w-full ${size}`}>
          {children}
        </DialogPanel>
      </div>
    </Dialog>
  );
};
