import {
  Button,
  ColumnChart,
  DateField,
  DonutChart,
  HookedForm,
  LineChart,
  SelectField,
} from '@app/components';
import StarFullIcon from '@app/assets/icons/star-full.svg?react';
import { useAnalyticsRatings } from './AnalyticsRatings.hooks';

export const AnalyticsRatings = () => {
  const { chartData, navigate } = useAnalyticsRatings();

  return (
    <>
      <div className="bg-white border-b border-b-base-ghostWhite ">
        <div className="container mx-auto p-4 flex justify-between items-center">
          <h2 className="font-bold text-[28px]">Ratings analytics</h2>
          <div className="flex gap-2">
            <Button className="text-sm font-semibold" kind="secondary">
              Export as PDF
            </Button>
          </div>
        </div>
        <div className="container mx-auto p-4">
          <HookedForm className="flex gap-4 ">
            <DateField name="start" containerClass="w-36" />
            <DateField name="end" containerClass="w-36 " />
            <SelectField
              defaultValue="all"
              name="connection"
              options={[{ label: 'All connections', value: 'all' }]}
            />
          </HookedForm>
        </div>
      </div>
      <div className="container mx-auto p-4 space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-white p-4 ">
            <div className="flex items-center justify-between">
              <h5 className="font-bold text-sm">Average rating</h5>
              <button className="bg-base-antiFlash p-2 rounded-lg">
                All reviews
              </button>
            </div>
            <hr className="my-4" />
            <div className="space-y-4 flex gap-10 items-center justify-center">
              <div>
                <h4 className="text-6xl font-bold">3.5</h4>
                <p className="text-base-davyGray text-sm">100 reviews</p>
              </div>
              <div>
                <ul>
                  <li className="text-base-davyGray text-sm flex items-center gap-2">
                    <StarFullIcon />
                    <span>5</span>
                    <div className="rounded-full w-36 bg-brand-lightBlue3 h-2 overflow-hidden">
                      <div className="bg-brand-baseBlue w-full h-full rounded-full" />
                    </div>
                    <span>159 (34.6%)</span>
                  </li>
                  <li className="text-base-davyGray text-sm flex items-center gap-2">
                    <StarFullIcon />
                    <span>4</span>
                    <div className="rounded-full w-36 bg-brand-lightBlue3 h-2 overflow-hidden">
                      <div className="bg-brand-baseBlue w-[80%] h-full rounded-full" />
                    </div>
                    <span>159 (34.6%)</span>
                  </li>
                  <li className="text-base-davyGray text-sm flex items-center gap-2">
                    <StarFullIcon />
                    <span>3</span>
                    <div className="rounded-full w-36 bg-brand-lightBlue3 h-2 overflow-hidden">
                      <div className="bg-brand-baseBlue w-[60%] h-full rounded-full" />
                    </div>
                    <span>159 (34.6%)</span>
                  </li>
                  <li className="text-base-davyGray text-sm flex items-center gap-2">
                    <StarFullIcon />
                    <span>2</span>
                    <div className="rounded-full w-36 bg-brand-lightBlue3 h-2 overflow-hidden">
                      <div className="bg-brand-baseBlue w-[40%] h-full rounded-full" />
                    </div>
                    <span>159 (34.6%)</span>
                  </li>
                  <li className="text-base-davyGray text-sm flex items-center gap-2">
                    <StarFullIcon />
                    <span>1</span>
                    <div className="rounded-full w-36 bg-brand-lightBlue3 h-2 overflow-hidden">
                      <div className="bg-brand-baseBlue w-[20%] h-full rounded-full" />
                    </div>
                    <span>159 (34.6%)</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 h-fit">
            <div className="flex items-center justify-between">
              <h5 className="font-bold text-sm">Review count by platform</h5>
              <button
                className="bg-base-antiFlash p-2 rounded-lg"
                onClick={() => navigate('/analytics/ratings/platforms')}
              >
                Open more
              </button>
            </div>
            <hr className="my-4" />
            <div className="space-y-4 flex items-center gap-4">
              <DonutChart />
            </div>
          </div>
        </div>
        <div className="bg-white p-4 h-fit">
          <div className="flex items-center justify-between">
            <h5 className="font-bold text-sm">Rating distribution</h5>
            <HookedForm>
              <SelectField
                defaultValue={'monthly'}
                className="bg-base-antiFlash p-2 rounded-lg"
                options={[{ label: 'Monthly', value: 'monthly' }]}
              />
            </HookedForm>
          </div>
          <hr className="my-4" />
          <div>
            <LineChart title="Rating" />
            <ColumnChart data={chartData} />
          </div>
        </div>
      </div>
    </>
  );
};
