import { Checkbox } from '@headlessui/react';
import { InputHTMLAttributes } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface CheckboxFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value?: string;
  onCheckMark?: (value?: string) => void;
}

export const CheckboxField = ({
  name,
  value: optionValue,
  onCheckMark,
  ...rest
}: CheckboxFieldProps) => {
  const {
    control,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name ?? ''}
      defaultValue={rest.defaultValue}
      render={({ field: { onChange, value } }) => {
        return (
          <Checkbox
            checked={rest.checked ?? value}
            name={name ?? ''}
            value={value}
            onChange={(e) => {
              onChange(e);
              if (onCheckMark) {
                onCheckMark(optionValue);
              }
            }}
            className="group block size-4 rounded border bg-white data-[checked]:bg-blue-500 data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 data-[checked]:data-[disabled]:bg-gray-500"
          >
            <svg
              className="stroke-white opacity-0 group-data-[checked]:opacity-100"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M3 8L6 11L11 3.5"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Checkbox>
        );
      }}
    />
  );
};
