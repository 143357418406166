import { BaseDialog, Button, PlanCard } from '@app/components';
import { motion } from 'framer-motion';
import {
  comparePlans,
  ContactUsEmail,
  MembershipTypes,
} from '@app/utils/consts';
import { useAppDispatch, useAppSelector } from '@app/redux/hooks';
import { PlanInterface } from '@app/models/plans';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getPlans, updatePlan } from '@app/services/plans';
import { fetchMe } from '@app/redux/features/auth';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { PlanUsageTable } from '@app/components/PlanUsageTable/PlanUsageTable';
import CloseIcon from '@app/assets/icons/close.svg?react';
import DiamondIcon from '@app/assets/icons/diamond.svg?react';
import BriefcaseIcon from '@app/assets/icons/briefcase.svg?react';

export const SettingsPlan = () => {
  const [selectedPlan, setSelectedPlan] = useState<PlanInterface | undefined>();
  const [lowerModal, setLowerModal] = useState(false);
  const [upperModal, setUpperModal] = useState(false);
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const business = useAppSelector((state) => state.business);
  const businessSubscription = auth?.user?.subscriptions?.find(
    (sub) => sub.businessId === business?.selected?.id
  );

  const handleSelectedPlan = (plan?: PlanInterface) => {
    if (!plan || !businessSubscription) {
      setSelectedPlan(undefined);
      setLowerModal(false);
      setUpperModal(false);
      return;
    }

    setSelectedPlan(plan);
    if (businessSubscription && plan) {
      if (businessSubscription?.plan.price > plan.price) {
        setLowerModal(true);
      }
      if (businessSubscription?.plan.price < plan.price) {
        setUpperModal(true);
      }
    }
  };

  const { data: plansResponse, isLoading } = useQuery({
    queryKey: ['get-plans'],
    queryFn: () => getPlans(),
  });

  const { mutate: updateSubscription, isPending } = useMutation({
    mutationKey: ['update-subscriptions'],
    mutationFn: (priceId: string) => updatePlan(priceId),
    onSuccess: () => {
      dispatch(fetchMe());
      handleSelectedPlan();
      toast.success('Subscription updated');
    },
    onError: (error) => {
      toast.error('Error updating subscriptions');
      console.error(error);
    },
  });
  useEffect(() => {
    if (auth.user && !auth.user.subscriptions) {
      dispatch(fetchMe());
    }
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="container mx-auto p-4"
      >
        <div className="container mx-auto p-4">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <PlanCard businessSubscription={businessSubscription} />
          </div>
          <hr className="my-6" />
          {isLoading && (
            <div className="p-4">Loading plans and features...</div>
          )}
          {!isLoading && (
            <>
              <div className="flex justify-end">
                <div className="flex gap-4"></div>
              </div>
              <PlanUsageTable
                businessSubscription={businessSubscription}
                handleSelectedPlan={handleSelectedPlan}
                plans={plansResponse}
                isPending={isPending}
              />
            </>
          )}
          <div className="my-16">
            <div className="max-w-xl mx-auto border-dashed border-2 border-base-lavender p-6 space-y-6 text-center rounded-xl">
              <h5 className="font-bold">Enterprise+ Plan</h5>
              <p className="text-base-davyGray">
                For plans thats we offer if you need more seats, review requests
                or integrations to your personal CRM, etc. This is available
                upon request.
              </p>
              <a href={`mailto:${ContactUsEmail}`} className="inline-block">
                <Button className="mx-auto">Contact us</Button>
              </a>
            </div>
          </div>
        </div>
      </motion.div>
      <BaseDialog open={upperModal} onClose={() => handleSelectedPlan()}>
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div className="bg-brand-lightBlue3 flex items-center p-2 rounded-lg">
            <DiamondIcon
              width={24}
              height={24}
              className="[&_path]:stroke-brand-baseBlue"
            />
          </div>
          <div className="mr-auto my-auto">
            <h5 className="font-bold text-lg leading-tight">
              Upgrade to{' '}
              <span className="capitalize">{selectedPlan?.type}</span>
            </h5>
          </div>
          <div>
            <button onClick={() => handleSelectedPlan()} type="button">
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6">
          <div className="mb-4 space-y-4">
            <p className="text-sm text-brand-darkBlue2 capitalize">
              {selectedPlan?.type} Plan
            </p>
            <p>
              <span className="font-bold text-3xl">${selectedPlan?.price}</span>{' '}
              <span className="text-base-coolGray">/montly</span>
            </p>
            <p className="text-sm text-base-davyGray">
              After payment, all features become immediately available
            </p>
          </div>
          <ul className="[&_li]:border-t [&_li]:py-2 text-sm">
            <li className="font-bold">Everything from lower plan, plus:</li>
            {comparePlans(
              selectedPlan?.type as MembershipTypes,
              businessSubscription?.plan.type as MembershipTypes
            )
              .filter((value) => value !== null)
              .map((difference, index) => {
                return (
                  <li key={index} className="text-base-davyGray">
                    {typeof difference.plan1Value === 'number'
                      ? difference.plan1Value
                      : ''}{' '}
                    {difference.title}
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="px-6 pb-6 flex items-center justify-between">
          <Button kind="danger" onClick={() => handleSelectedPlan()}>
            Cancel
          </Button>
          <Button
            onClick={() => updateSubscription(selectedPlan?.priceId as string)}
          >
            Upgrade
          </Button>
        </div>
      </BaseDialog>
      <BaseDialog open={lowerModal} onClose={() => handleSelectedPlan()}>
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <BriefcaseIcon
              width={24}
              height={24}
              className="[&_path]:stroke-brand-baseBlue"
            />
          </div>
          <div className="mr-auto my-auto">
            <h5 className="font-bold text-lg leading-tight">
              Downgrade to{' '}
              <span className="capitalize">{selectedPlan?.type}</span>
            </h5>
          </div>
          <div>
            <button type="button" onClick={() => handleSelectedPlan()}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6">
          <div className="mb-4 space-y-4">
            <p className="text-sm text-brand-darkBlue2 capitalize">
              {selectedPlan?.type} Plan
            </p>
            <p>
              <span className="font-bold text-3xl">${selectedPlan?.price}</span>{' '}
              <span className="text-base-coolGray">/montly</span>
            </p>
            <p className="text-sm text-base-davyGray">
              After payment, all features become immediately available
            </p>
          </div>
          <ul className="[&_li]:border-t [&_li]:py-2 text-sm">
            <li className="font-bold">You will lose:</li>
            {comparePlans(
              businessSubscription?.plan?.type as MembershipTypes,
              selectedPlan?.type as MembershipTypes
            )
              .filter((value) => value !== null)
              .map((difference, index) => (
                <li key={index}>
                  {typeof difference.plan1Value === 'number'
                    ? difference.plan1Value
                    : ''}{' '}
                  {difference.title}
                </li>
              ))}
          </ul>
        </div>
        <div className="px-6 pb-6 flex items-center justify-between">
          <Button kind="secondary" onClick={() => handleSelectedPlan()}>
            Cancel
          </Button>
          <Button
            onClick={() => updateSubscription(selectedPlan?.priceId as string)}
          >
            Downgrade
          </Button>
        </div>
      </BaseDialog>
    </>
  );
};
