import axios from 'axios';
import { ApiBaseRoute } from './consts';
import { QueryClient } from '@tanstack/react-query';

export const apiInstance = axios.create({
  baseURL: ApiBaseRoute,
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

apiInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.set('Authorization', `Bearer ${token}`);
    }

    const businessId = localStorage.getItem('businessId');
    if (businessId) {
      config.headers.set('ECHO-Business-ID', businessId);
    }

    return config;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('businessId');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('businessId');
      window.location.href = '/login';
    }
    return Promise.reject(error?.response);
  }
);
