import { Link, matchRoutes, useLocation } from 'react-router-dom';
import { useAppSelector } from '@app/redux/hooks';
import { sideDrawerItems } from '@app/utils/consts';
import HorizontalLogo from '@app/assets/horizontal-logo.svg?react';
import SettingsIcon from '@app/assets/icons/menu/settings.svg?react';
import DotsVerticalIcon from '@app/assets/icons/menu/dot-vertical.svg?react';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

export const SideDrawer = () => {
  const [expandedSubItem, setExpandedSubItem] = useState<number | undefined>();
  const businessState = useAppSelector((state) => state.business);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      const index = sideDrawerItems.findIndex((item) => {
        const childrenPaths =
          item.children?.map((child) => ({
            path: child.to,
          })) ?? [];

        return matchRoutes(
          [...childrenPaths, { path: item.to }],
          location.pathname
        );
      });
      setExpandedSubItem(index);
    }
  }, [location.pathname]);

  return (
    <div className="bg-white h-full  flex flex-col items-start border-r">
      <div className="border-b border-b-base-ghostWhite p-4 w-full">
        <HorizontalLogo width={126} height={56} />
      </div>
      <div className="border-b border-b-base-ghostWhite p-4 w-full">
        <div className="flex items-center justify-between gap-2">
          <div
            className="w-8 h-8 flex items-center justify-center  rounded-lg shrink-0"
            style={{ color: `#424244`, background: `#42424414` }}
          >
            <span className="font-bold text-lg">
              {businessState?.selected?.name?.charAt(0)}
            </span>
          </div>
          <h5 className="font-bold text-sm mr-auto break-all">
            {businessState?.selected?.name}
          </h5>
          <Link to="/select-business" className="shrink-0">
            <DotsVerticalIcon />
          </Link>
        </div>
      </div>
      <div className="w-full relative">
        <ul className="flex gap-4 flex-col text-sm">
          {sideDrawerItems.map((item, index) => (
            <li key={index}>
              {item.children && item.children.length > 0 ? (
                <span
                  onClick={() => {
                    setExpandedSubItem(
                      expandedSubItem === index ? undefined : index
                    );
                  }}
                  className={clsx(
                    'flex gap-2 items-center hover:bg-brand-lightBlue4 px-4 py-2 rounded-lg cursor-pointer',
                    {
                      'font-bold bg-brand-lightBlue4': matchRoutes(
                        [{ path: item.to }],
                        location.pathname
                      ),
                    }
                  )}
                >
                  {item.Icon && <item.Icon />} {item.label}
                </span>
              ) : (
                <Link
                  className={clsx(
                    'flex relative gap-2 items-center hover:bg-brand-lightBlue4 px-4 py-2 rounded-lg cursor-pointer',
                    {
                      'bg-brand-lightBlue4 text-brand-baseBlue font-bold side-tab':
                        matchRoutes([{ path: item.to }], location.pathname),
                    }
                  )}
                  to={item.to}
                >
                  {item.Icon && <item.Icon />} {item.label}
                </Link>
              )}

              {item.children &&
                item.children?.length > 0 &&
                expandedSubItem === index && (
                  <ul>
                    {item.children?.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <Link
                          className={clsx(
                            'relative flex gap-2  items-center hover:bg-brand-lightBlue4 px-4 py-2 rounded-lg',
                            {
                              'font-bold bg-brand-lightBlue4 text-brand-baseBlue side-tab':
                                matchRoutes(
                                  [{ path: subItem.to }],
                                  location.pathname
                                ),
                            }
                          )}
                          to={subItem.to}
                        >
                          {subItem.Icon && <subItem.Icon />}{' '}
                          <span className="ml-10">{subItem.label}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-auto p-4">
        <div className="bg-system-successLight p-2 rounded-lg mb-2">
          <h5 className="text-system-successDark text-sm font-bold mb-1">
            Leave feedback
          </h5>
          <p className="text-xs text-base-davyGray">
            Leave feedback on our platform so we can make it better
          </p>
        </div>
        <div>
          <Link
            className="flex gap-2 mb-2 items-center hover:bg-brand-lightBlue4 p-1 rounded-lg"
            to="/settings"
          >
            <SettingsIcon />
            Settings
          </Link>
        </div>
      </div>
    </div>
  );
};
