import { InputHTMLAttributes, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import EyeIcon from '@app/assets/icons/eye.svg?react';
import EyeClosedIcon from '@app/assets/icons/close-eye.svg?react';
import clsx from 'clsx';
import { debounce } from 'lodash';

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  containerClass?: string;
  isOptional?: boolean;
  externalHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputField = ({
  name,
  label,
  containerClass,
  isOptional,
  externalHandler,
  ...rest
}: InputFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  return (
    <div className={`w-full relative ${containerClass ?? ''}`}>
      {label && (
        <label htmlFor={name} className="font-medium text-sm inline-block mb-1">
          {label} {isOptional && <span>(optional)</span>}
        </label>
      )}
      <input
        {...rest}
        {...(rest.placeholder
          ? isOptional
            ? { placeholder: rest.placeholder + ' (optional)' }
            : { placeholder: rest.placeholder }
          : {})}
        type={
          rest.type === 'password'
            ? showPassword
              ? 'text'
              : 'password'
            : rest.type
        }
        {...register(name ?? '')}
        onChange={debounce((e) => {
          register(name ?? '').onChange(e);

          if (externalHandler) {
            externalHandler(e);
          }
        }, 500)}
        className={clsx(
          `bg-base-seasalt rounded py-3 px-4 w-full outline-brand-baseBlue outline-1 ${rest.className ?? ''}`,
          { 'border border-system-error': name && errors[name] }
        )}
      />
      {rest.type === 'password' && (
        <button
          type="button"
          className="absolute top-3 right-3"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          {!showPassword ? (
            <EyeIcon width={24} height={24} />
          ) : (
            <EyeClosedIcon width={24} height={24} />
          )}
        </button>
      )}
      {name && errors[name] && (
        <p role="alert" className="text-system-error text-sm text-left pt-1">
          {typeof errors?.[name]?.message === 'string'
            ? errors[name]?.message
            : ''}
        </p>
      )}
    </div>
  );
};
