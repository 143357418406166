import { isEqual } from 'lodash';
import { PlanInterface } from '@app/models/plans';
import { DateTime } from 'luxon';
import { IntegrationType } from './consts';
import DiamondIcon from '@app/assets/icons/diamond.svg?react';
import BriefcaseIcon from '@app/assets/icons/briefcase.svg?react';
import HourglassIcon from '@app/assets/icons/hourglass.svg?react';
import EnterpriseIcon from '@app/assets/icons/enterprise.svg?react';
import FacebookIcon from '@app/assets/icons/Facebook-Small-Logo.svg?react';
import TripadvisorIcon from '@app/assets/icons/Trustpilot.svg?react';
import GoogleLogoIcon from '@app/assets/icons/Google.svg?react';
import YelpIcon from '@app/assets/icons/integrations/yelp.svg?react';

export const getLastTwelveMonths = () => {
  const months = [];
  for (let i = 0; i < 12; i++) {
    const d = new Date();
    d.setMonth(d.getMonth() - i);
    const formattedMonth = DateTime.fromJSDate(d).toFormat('LLL yyyy');
    months.push(formattedMonth);
  }
  return months.reverse();
};

export const getMembershipInfo = (membership: string) => {
  switch (membership) {
    case 'Enterprise': {
      return {
        color: { start: '#52B6FE', end: '#F5B8FF' },
        name: 'Enterprise',
        description: 'This is the Enterprise membership',
        Icon: EnterpriseIcon,
      };
    }
    case 'Business': {
      return {
        color: { start: '#52B6FE', end: '#0094FF' },
        name: 'Business',
        description: 'This is the Business membership',
        Icon: BriefcaseIcon,
      };
    }
    case 'Professional': {
      return {
        color: { start: '#52B6FE', end: '#4ADA73' },
        name: 'Professional',
        description: 'This is the Professional membership',
        Icon: DiamondIcon,
      };
    }
    case 'Trial': {
      return {
        color: { start: '#CBE9FF', end: '#CBE9FF' },
        name: 'Trial',
        description: 'This is the Trial membership',
        Icon: HourglassIcon,
      };
    }
    default: {
      return {
        color: { start: '#52B6FE', end: '#F5B8FF' },
        name: 'Enterprise',
        description: 'This is the Enterprise membership',
        Icon: HourglassIcon,
      };
    }
  }
};

export const formatPrice = (price?: string | number, decimals?: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals ?? 0,
    maximumFractionDigits: decimals ?? 0,
  }).format(Number(price) ?? 0);
};

export const getObjectDiff = (
  obj1: { [x: string]: string | number | boolean | {} },
  obj2: { [x: string]: string | number | boolean | {} },
  compareRef = false
) => {
  return Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);

      if (compareRef && obj1[key] !== obj2[key]) {
        result[resultKeyIndex] = `${key} (ref)`;
      } else {
        result.splice(resultKeyIndex, 1);
      }
    }
    return result;
  }, Object.keys(obj2));
};

export const getPlanButtonAndText = (
  currentPlan: PlanInterface,
  compare: PlanInterface
): {
  button: 'primary' | 'secondary' | 'tertiary';
  text: string;
  disabled: boolean;
} => {
  if (currentPlan?.price > compare?.price) {
    return { button: 'secondary', text: 'Lower the plan', disabled: false };
  }
  if (currentPlan?.price === compare?.price) {
    return { button: 'secondary', text: 'Current plan', disabled: true };
  }

  return { button: 'primary', text: 'Upgrade', disabled: false };
};

export const downloadFile = (url: string, name: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const groupReviewsByRatingAndMonth = (reviews?: any[]) => {
  if (!reviews) return {};

  const groupedReviews: { [key: string]: { [rating: number]: number } } = {};

  reviews.forEach((review) => {
    const month = new Date(review.time_created).toLocaleString('default', {
      month: 'short',
      year: 'numeric',
    });
    if (!groupedReviews[month]) {
      groupedReviews[month] = {};
    }
    if (!groupedReviews[month][review.rating]) {
      groupedReviews[month][review.rating] = 0;
    }
    groupedReviews[month][review.rating]++;
  });

  return groupedReviews;
};

export const getIntegrationIcon = (type: IntegrationType) => {
  switch (type) {
    case IntegrationType.YELP:
      return YelpIcon;
    case IntegrationType.GOOGLE:
      return GoogleLogoIcon;
    case IntegrationType.TRUSTPILOT:
      return TripadvisorIcon;
    case IntegrationType.FACEBOOK:
      return FacebookIcon;
    default:
      return null;
  }
};

export const validateColumns = (
  columns: string[],
  requiredColumns: string[]
) => {
  const missingColumns = requiredColumns.filter(
    (requiredColumn) => !columns.includes(requiredColumn)
  );

  return missingColumns;
};
