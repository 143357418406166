export const PlanUsageCard = () => {
  return (
    <div className="bg-white p-4 h-fit">
      <div className="flex items-center justify-between p-2">
        <h5 className="font-bold text-sm">Usage overview</h5>
      </div>
      <hr className="my-4 border-base-ghostWhite" />
      <table>
        <tbody>
          <tr>
            <td className="w-[130px]">
              <span className="text-xs text-base-coolGray">CAMPAIGNS</span>
            </td>
            <td>
              <span className="text-sm font-medium">
                695 campaigns left until 13 December, 2023
              </span>
            </td>
          </tr>
          <tr>
            <td className="w-[130px]">
              <span className="text-xs text-base-coolGray">MEMBER SEATS</span>
            </td>
            <td>
              <span className="text-sm font-medium">
                <b>1 seat</b> left
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
