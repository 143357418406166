import { CustomError } from '@app/models/general';
import { useAppDispatch, useAppSelector } from '@app/redux/hooks';
import { register, registerWithGoogle } from '@app/services/auth';
import { getCategories } from '@app/services/business';
import { registrationValidation } from '@app/utils/validations';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const useSignUpPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const businessState = useAppSelector((state) => state.business);
  const [signupError, setSignupError] = useState<string | undefined>();
  const signupForm = useForm({ resolver: registrationValidation });

  const { data: businessCategories = [], isLoading } = useQuery({
    queryKey: ['businessCategories'],
    queryFn: () => getCategories(),
  });

  const { mutate, isPending } = useMutation({
    mutationKey: ['register'],
    mutationFn: (data: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
      business: { name: string; address: string; category: string };
      phoneNumber: string;
    }) => register(data),
    onSuccess: (res) => {
      dispatch({ type: 'auth/save', payload: res?.data?.user });
      dispatch({ type: 'business/loadList', payload: res?.data?.businesses });
      dispatch({
        type: 'business/selectBusiness',
        payload: { id: res?.data?.businesses?.[0]?.id },
      });
      localStorage.setItem('accessToken', res?.data?.accessToken);
      navigate('/welcome');
    },
    onError: (err: CustomError) => {
      console.error(err);
      setSignupError(
        err?.data?.error?.message ?? 'Error while creating account'
      );
    },
  });

  const submitSignup = async (values: FieldValues) => {
    setSignupError(undefined);
    mutate({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      phoneNumber: values.phoneNumber,
      business: {
        name: values.businessName,
        address: values.businessAddress,
        category: values.businessCategory,
      },
    });
  };

  const submitGoogleSignup = () => {
    registerWithGoogle()
      .then((res) => console.log(res))
      .then((err) => console.error(err));
  };

  return {
    signupForm,
    submitSignup,
    signupError,
    submitGoogleSignup,
    businessState,
    isPending,
    isLoading,
    businessCategories,
  };
};
