import { CreateTagRequest } from '@app/models/requests';
import { apiInstance } from './api';
import { ApiRoutes } from './consts';

export const createTag = async (data: CreateTagRequest) => {
  return apiInstance.post(
    `${ApiRoutes.Business}${ApiRoutes.Tags}/create`,
    data
  );
};

export const getTag = async () => {
  return apiInstance.get(`${ApiRoutes.Business}${ApiRoutes.Tags}/`);
};
