import { InputHTMLAttributes, useCallback, useState } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import { Button } from '../Button/Button';
import Papa from 'papaparse';
import UploadIcon from '@app/assets/icons/upload.svg?react';
import UploadSuccessIcon from '@app/assets/icons/upload-success.svg?react';
import FileIcon from '@app/assets/icons/file.svg?react';
import CloseIcon from '@app/assets/icons/close.svg?react';
import { validateColumns } from '@app/utils/utils';

interface InputFieldProps extends InputHTMLAttributes<File> {
  name?: string;
  multiple?: boolean;
  allowedTypes?: Accept | undefined;
  disableConfirm?: boolean;
  isUploading?: boolean;
  expectedColumns?: string[];
}

export const FileDropField = ({
  name = 'file-drop',
  multiple,
  allowedTypes,
  disableConfirm,
  isUploading,
  expectedColumns,
  ...rest
}: InputFieldProps) => {
  const {
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const [progress, setProgress] = useState<number>(0);
  const [fileName, setFileName] = useState<string | undefined>();
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false);

  const resetProgress = () => {
    setFileName(undefined);
    setProgress(0);
    setFinishedLoading(false);
    setValue(name, null);
  };

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles && rejectedFiles.length > 0) {
        setValue(name, []);

        setError(name, {
          type: 'manual',
          message: rejectedFiles && rejectedFiles[0].errors[0].message,
        });
      } else {
        clearErrors(name);
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();
          reader.onloadstart = () => setFileName(file?.name);
          reader.onprogress = (ev) =>
            setProgress(
              Math.floor((Number(ev.loaded ?? 0) / Number(ev.total ?? 0)) * 100)
            );
          reader.onabort = () => {
            resetProgress();
            console.info('File reading was aborted');
          };
          reader.onerror = () => {
            resetProgress();
            console.error('file reading has failed');
          };
          reader.readAsDataURL(file as unknown as Blob);
          reader.onloadend = () => {
            if (expectedColumns && file.type === 'text/csv') {
              Papa.parse(file, {
                complete: function (results) {
                  const missingColumns = validateColumns(
                    results.data[0] as string[],
                    expectedColumns
                  );
                  if (missingColumns.length > 0) {
                    setError(name, {
                      type: 'manual',
                      message: `The following columns are missing: ${missingColumns.join(
                        ', '
                      )}\nPlease upload a file with the correct columns or use the template provided.`,
                    });
                    resetProgress();
                    return;
                  }
                },
              });
            }

            setValue(name, file, { shouldValidate: true });
            setFinishedLoading(true);
          };
        });
      }
    },
    [name, setValue, setError, clearErrors]
  );

  const { getRootProps, getInputProps } = useDropzone({
    // @ts-ignore
    onDrop,
    multiple,
    // @ts-ignore
    accept: allowedTypes,
    ...rest,
  });

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <>
          {!fileName ? (
            <>
              <div
                {...getRootProps()}
                className="cursor-pointer rounded-xl border-dashed border-base-lavender bg-white border w-full flex items-center flex-col p-4"
              >
                <input {...getInputProps({ onChange })} />
                <UploadIcon />
                <p className="font-bold text-base-raisinBlack text-sm">
                  Drag & Drop
                </p>
                <p className="text-base-coolGray text-xs font-medium">
                  or click here to upload
                </p>
              </div>
              {name && errors[name] && (
                <div className=" rounded-xl  bg-white  w-full flex items-start justify-between gap-2 p-4 mt-4 text-system-error whitespace-pre">
                  {typeof errors?.[name]?.message === 'string'
                    ? errors[name]?.message
                    : ''}
                </div>
              )}
            </>
          ) : (
            <div>
              {!finishedLoading && (
                <div className="cursor-pointer rounded-xl  bg-white  w-full flex items-start justify-start gap-2 p-4">
                  <div>
                    <FileIcon />
                  </div>
                  <div className="space-y-2 w-full">
                    <p className="font-bold text-sm">{fileName}</p>
                    <div className="bg-brand-baseBlue/50 w-full h-2 rounded-full overflow-hidden">
                      <span
                        style={{
                          width: `${progress}%`,
                        }}
                        className=" rounded-full bg-brand-baseBlue h-full block"
                      ></span>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      resetProgress();
                    }}
                    className="w-10 h-10 flex items-center justify-center rounded-lg bg-base-antiFlash"
                  >
                    <CloseIcon />
                  </button>
                </div>
              )}
              {finishedLoading && (
                <div className="cursor-pointer rounded-xl  bg-white  w-full flex items-start justify-between gap-2 p-4">
                  <div>
                    <UploadSuccessIcon />
                  </div>
                  <div className="mr-auto">
                    <p className="font-bold">Your file has been uploaded</p>
                    <p className="text-sm text-base-coolGray">{fileName}</p>
                  </div>
                  <div className="flex gap-2 ">
                    <Button
                      loading={isUploading}
                      disabled={disableConfirm || isUploading}
                      type="submit"
                    >
                      Confirm
                    </Button>
                    <Button
                      type="button"
                      kind="danger"
                      className="min-w-fit px-4"
                      onClick={() => resetProgress()}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    />
  );
};
