import { ReactNode } from 'react';

interface GenericTableRowProps {
  id: string;
  render: ReactNode;
}

export const GenericTableRow = ({ id, render }: GenericTableRowProps) => {
  return (
    <tr
      data-id={id}
      className="border-b border-base-antiFlash last-of-type:border-b-0 text-xs cursor-pointer hover:bg-brand-lightBlue3"
    >
      {render}
    </tr>
  );
};
