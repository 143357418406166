import { UserInterface } from '@app/models/user';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from '../withTypes';
import { getProfile, getUserWithBusinesses } from '@app/services/user';

export interface AuthState {
  user: UserInterface | undefined;
}

const initialState: AuthState = {
  user: undefined,
};

export const fetchUpdate = createAppAsyncThunk('auth/fetchUpdate', async () => {
  const response = await getProfile();
  return response?.data;
});

export const fetchMe = createAppAsyncThunk('auth/fetchMe', async () => {
  const response = await getUserWithBusinesses();
  return response?.data?.user;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    save: (state, action: PayloadAction<UserInterface>) => {
      state.user = action.payload;
    },
    update: (state, action: PayloadAction<UserInterface>) => {
      state.user = { ...state.user, ...action.payload };
    },
    logout: (state) => {
      localStorage?.removeItem('accessToken');
      state.user = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUpdate.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(fetchMe.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export const { save, update, logout } = authSlice.actions;

export default authSlice.reducer;
