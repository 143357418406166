import { yupResolver } from '@hookform/resolvers/yup';
import { PhoneNumberUtil } from 'google-libphonenumber';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import { IntegrationType } from './consts';

YupPassword(yup);

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

yup.addMethod(yup.string, 'phoneValidation', function () {
  return this.test(
    `test-phone-number`,
    'Phone number is not valid',
    (str, context) => {
      if (context?.schema?.spec?.optional && (!str || str === '+1')) {
        return true;
      }

      if (!str && !context?.schema?.spec?.optional) return false;

      const pn = isPhoneValid(str as string);

      return pn;
    }
  );
});

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const loginValidation = yupResolver(
  yup
    .object({
      email: yup
        .string()
        .trim()
        .matches(EMAIL_REGEX, 'Invalid email format')
        .max(255)
        .required('Email is required'),
      password: yup.string().trim().required().label('Password'),
    })
    .required()
);

export const registrationValidation = yupResolver(
  yup.object({
    firstName: yup
      .string()
      .trim()
      .min(1)
      .label('First Name')
      .max(255)
      .required('First name is required'),
    lastName: yup
      .string()
      .trim()
      .label('Last Name')
      .min(1)
      .max(255)
      .required('Last name is required'),
    email: yup
      .string()
      .matches(EMAIL_REGEX, 'Invalid email format')
      .trim()
      .min(1)
      .max(255)
      .required('Email is required'),
    password: yup
      .string()
      .trim()
      .password()
      .minLowercase(1, 'A password must contain at least 1 lowercase')
      .minUppercase(1, 'A password must contain at least 1 Uppercase')
      .minNumbers(1, 'A password must contain at least 1 number')
      .minSymbols(1, 'A password must contain at least 1 symbol')
      .min(8, 'A password must be at leat 8 characters long')
      .required()
      .label('Password'),
    phoneNumber: yup
      .string()
      .trim()
      .phoneValidation()
      .optional()
      .label('Phone Number'),
    businessName: yup
      .string()
      .trim()
      .min(1)
      .label('Business Name')
      .max(255)
      .required('Business name is required'),
    businessCategory: yup.string().required('Business category is required'),
    businessAddress: yup
      .string()
      .trim()
      .label('Business Address')
      .min(1)
      .max(255)
      .required('Business address is required'),
  })
);

export const newPasswordValidation = yupResolver(
  yup.object({
    password: yup
      .string()
      .trim()
      .password()
      .minLowercase(1, 'A password must contain at least 1 lowercase')
      .minUppercase(1, 'A password must contain at least 1 Uppercase')
      .minNumbers(1, 'A password must contain at least 1 number')
      .minSymbols(1, 'A password must contain at least 1 symbol')
      .min(8, 'A password must be at leat 8 characters long')
      .required()
      .label('Password'),
    confirmPassword: yup
      .string()
      .trim()
      .password()
      .minLowercase(1, 'A password must contain at least 1 lowercase')
      .minUppercase(1, 'A password must contain at least 1 Uppercase')
      .minNumbers(1, 'A password must contain at least 1 number')
      .minSymbols(1, 'A password must contain at least 1 symbol')
      .min(8, 'A password must be at leat 8 characters long')
      .required()
      .oneOf([yup.ref('password')], 'Your passwords do not match.')
      .label('Password'),
  })
);

export const forgotPasswordValidation = yupResolver(
  yup
    .object({
      email: yup
        .string()
        .trim()
        .matches(EMAIL_REGEX, 'Invalid email format')
        .min(1)
        .max(255)
        .required('Email is required'),
    })
    .required()
);

export const createCustomerValidation = yupResolver(
  yup.object({
    firstName: yup
      .string()
      .min(1)
      .label('First Label')
      .trim()
      .required('First name is required'),
    lastName: yup
      .string()
      .min(1)
      .label('Last Name')
      .trim()
      .required('Last name is required'),
    email: yup
      .string()
      .trim()
      .matches(EMAIL_REGEX, 'Invalid email format')
      .required('Email is required'),
    phoneNumber: yup
      .string()
      .trim()
      .phoneValidation()
      .optional()
      .label('Phone Number'),
    notes: yup.string().trim().optional(),
    tags: yup.array().of(yup.string().trim()).optional(),
    lists: yup.array().of(yup.string().trim()).optional(),
  })
);

export const createListValidation = yupResolver(
  yup.object({
    name: yup.string().min(1).label('Name').trim().required('Name is required'),
    customers: yup.array().of(yup.string().trim()).optional(),
    notes: yup.string().trim().optional(),
  })
);

export const createTagValidation = yupResolver(
  yup.object({
    name: yup.string().min(1).label('Name').trim().required('Name is required'),
    customers: yup.array().of(yup.string().trim()).optional(),
  })
);

export const updatePasswordEmailValidation = yupResolver(
  yup.object({
    email: yup
      .string()
      .trim()
      .label('Email')
      .matches(EMAIL_REGEX, 'Invalid email format')
      .required('Email is required'),
  })
);

export const updateProfileEmailValidation = yupResolver(
  yup.object({
    email: yup
      .string()
      .trim()
      .label('Email')
      .matches(EMAIL_REGEX, 'Invalid email format')
      .required('Email is required'),
  })
);

export const updateProfilePhoneValidation = yupResolver(
  yup.object({
    phoneNumber: yup
      .string()
      .trim()
      .phoneValidation()
      .required()
      .label('Phone Number'),
  })
);

export const updatePhoneValidation = yupResolver(
  yup.object({
    code: yup
      .string()
      .trim()
      .min(1)
      .label('OTP Code')
      .required('OTP Code is required'),
  })
);

export const updateProfileValidation = yupResolver(
  yup.object({
    firstName: yup
      .string()
      .min(1)
      .trim()
      .label('First Name')
      .required('First name is required'),
    lastName: yup
      .string()
      .min(1)
      .trim()
      .label('Last Name')
      .required('Last name is required'),
  })
);

export const createIntegrationValidation = yupResolver(
  yup.object({
    type: yup
      .string()
      .oneOf(Object.values(IntegrationType))
      .trim()
      .required('Invalid integration type'),
    connectionId: yup.string().trim(),
  })
);

export const updateIntegrationValidation = yupResolver(
  yup.object({
    type: yup
      .string()
      .oneOf(Object.values(IntegrationType))
      .trim()
      .required('Invalid integration type'),
    connectionId: yup.string().trim(),
  })
);

export const updateCustomerValidation = yupResolver(
  yup.object({
    firstName: yup.string().min(1).trim().required('First name is required'),
    lastName: yup.string().min(1).trim().required('Last name is required'),
    email: yup
      .string()
      .trim()
      .matches(EMAIL_REGEX, 'Invalid email format')
      .required('Email is required'),
    phoneNumber: yup
      .string()
      .trim()
      .phoneValidation()
      .optional()
      .label('Phone Number'),
    notes: yup.string().trim().optional(),
    tags: yup.array().of(yup.string().trim()).optional(),
    lists: yup.array().of(yup.string().trim()).optional(),
  })
);
