import {
  BaseDialog,
  Button,
  CheckSelectField,
  HookedForm,
  InputField,
  Loading,
} from '@app/components';
import { useListPage } from './Lists.hooks';
import { TextField } from '@app/components/Fields/TextField';
import { ListsTable } from '@app/components/ListsTable/ListsTable';
import { ListInterface } from '@app/models/util';
import AddIcon from '@app/assets/icons/add.svg?react';
import FolderIcon from '@app/assets/icons/folder.svg?react';
import CloseIcon from '@app/assets/icons/close.svg?react';
import FolderIllustration from '@app/assets/illustrations/folder.svg?react';
import { CustomerInterface } from '@app/models/business';

export const Lists = () => {
  const {
    addListOpen,
    addTagOpen,
    handleAddList,
    handleAddTag,
    createListForm,
    createTagForm,
    submitList,
    submitTag,
    isPendingList,
    isPendingTag,
    listsData,
    isLoading,
    customers,
    handleLimit,
    handleNextPage,
    handlePrevPage,
    listQuery,
  } = useListPage();

  const allCustomers =
    customers?.data?.customers?.map((customer: CustomerInterface) => ({
      label: `${customer.firstName} ${customer.lastName}`,
      value: customer.id,
    })) ?? [];

  return (
    <>
      <div>
        <div className="bg-white border-b border-b-base-ghostWhite ">
          <div className="container mx-auto p-4 flex justify-between items-center flex-col md:flex-row">
            <h2 className="font-bold text-[28px]">Lists</h2>
            <div className="flex flex-col md:flex-row gap-2">
              <Button
                onClick={handleAddList}
                leftNode={<AddIcon />}
                className="text-sm font-semibold"
              >
                Create new list
              </Button>
              <Button
                onClick={handleAddTag}
                leftNode={<AddIcon />}
                className="text-sm font-semibold"
              >
                Create new tag
              </Button>
            </div>
          </div>
        </div>
        <div className="container mx-auto p-4">
          {!isLoading && listsData && listsData?.lists?.length && (
            <ListsTable
              lists={(listsData?.lists as ListInterface[]) ?? []}
              handleLimit={handleLimit}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
              page={listQuery.page}
              total={listsData?.count ?? 0}
            />
          )}
          {isLoading && <Loading />}
          {!isLoading && listsData && listsData?.count === 0 && (
            <div className="flex items-center justify-center flex-col max-w-[350px] mx-auto space-y-4 h-full mt-20">
              <FolderIllustration />
              <p className="font-bold">No lists</p>
              <p className="text-sm text-base-coolGray text-center">
                Organize your customers more easily by
                <br /> grouping them into lists
              </p>
              <Button leftNode={<AddIcon />} onClick={handleAddList}>
                Create new list
              </Button>
            </div>
          )}
        </div>
      </div>
      <BaseDialog size="max-w-2xl" open={addListOpen} onClose={handleAddList}>
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div></div>
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <FolderIcon width={24} height={24} />
          </div>
          <div className="mr-auto">
            <h5 className="font-bold text-lg">Creat new list</h5>
            <p className="text-base-coolGray text-sm">
              Fill in the fields below and create a new list
            </p>
          </div>
          <div>
            <button type="button" onClick={handleAddList}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6 pb-4">
          <HookedForm
            formHook={createListForm}
            onSubmit={submitList}
            className="space-y-4"
          >
            <InputField name="name" label="Name" />
            <CheckSelectField
              showPill
              name="customers"
              label="Add customer(s)"
              isOptional
              options={allCustomers}
            />

            <TextField name="notes" label="Notes" isOptional />
            <div className="flex items-center gap-4 justify-center ">
              <Button
                kind="secondary"
                className="w-full"
                type="button"
                onClick={handleAddList}
              >
                Cancel
              </Button>
              <Button
                disabled={isPendingList}
                loading={isPendingList}
                className="w-full"
                type="submit"
              >
                Create list
              </Button>
            </div>
          </HookedForm>
        </div>
      </BaseDialog>
      <BaseDialog size="max-w-2xl" open={addTagOpen} onClose={handleAddTag}>
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div></div>
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <FolderIcon width={24} height={24} />
          </div>
          <div className="mr-auto">
            <h5 className="font-bold text-lg">Create new tag</h5>
            <p className="text-base-coolGray text-sm">
              Fill in the fields below and create a new tag
            </p>
          </div>
          <div>
            <button type="button" onClick={handleAddTag}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6 pb-4">
          <HookedForm
            formHook={createTagForm}
            onSubmit={submitTag}
            className="space-y-4"
          >
            <InputField name="name" label="Name" />
            <CheckSelectField
              showPill
              name="customers"
              label="Add customer(s)"
              isOptional
              options={allCustomers}
            />

            <div className="flex items-center gap-4 justify-center ">
              <Button
                kind="secondary"
                className="w-full"
                type="button"
                onClick={handleAddTag}
              >
                Cancel
              </Button>
              <Button
                disabled={isPendingTag}
                loading={isPendingTag}
                className="w-full"
                type="submit"
              >
                Create tag
              </Button>
            </div>
          </HookedForm>
        </div>
      </BaseDialog>
    </>
  );
};
