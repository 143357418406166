import LoadingGear from '@app/assets/illustrations/gear.svg?react';
import MiddleGear from '@app/assets/illustrations/middle-gear.svg?react';

export const Loading = () => {
  return (
    <div className=" flex items-center flex-col gap-6 p-6">
      <div className="relative">
        <LoadingGear />
        <MiddleGear className="absolute top-0 animate-spin" />
      </div>
      <p className="font-medium text-base-coolGray">Loading content...</p>
    </div>
  );
};
