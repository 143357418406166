import { Chart, GoogleChartOptions } from 'react-google-charts';

export const data = [
  ['Rating', 'ratings'],
  [1, 2],
  [2, 2],
  [3, 5],
  [4, 8],
  [5, 9],
  [6, 4],
];

export const options: Partial<GoogleChartOptions> = {
  legend: 'none',
  vAxis: {
    viewWindowMode: 'explicit',
    viewWindow: {
      max: 5,
      min: 1,
      interval: 1,
    },
  },

  hAxis: {
    textPosition: 'none',
    gridlines: {
      color: 'transparent',
    },
  },
};

export const LineChart = ({
  title,
  opt,
}: {
  title?: string;
  opt?: Partial<GoogleChartOptions>;
}) => {
  return (
    <>
      {title && <h5 className="text-xs text-brand-darkBlue3">{title}</h5>}
      <Chart
        chartType="LineChart"
        width="100%"
        height="400px"
        data={data}
        options={{ options, ...opt }}
      />
    </>
  );
};
