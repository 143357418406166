import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

interface PhoneFieldProps {
  name?: string;
  defaultCountry?: string;
  label?: string;
  defaultValue?: string;
  className?: string;
  placeholder?: string;
  isOptional?: boolean;
}

export const PhoneField = ({
  name,
  defaultCountry = 'us',
  label,
  defaultValue,
  className,
  isOptional,
  placeholder,
}: PhoneFieldProps) => {
  const {
    control,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name ?? ''}
      render={({ field: { onChange, value } }) => {
        return (
          <div className="w-full">
            {label && (
              <label
                htmlFor={name}
                className="font-medium text-sm inline-block mb-1"
              >
                {label}{' '}
                {isOptional && (
                  <span className="text-base-coolGray">(Optional)</span>
                )}
              </label>
            )}
            <PhoneInput
              {...(placeholder
                ? isOptional
                  ? { placeholder: placeholder + ' (optional)' }
                  : { placeholder: placeholder }
                : {})}
              defaultCountry={defaultCountry}
              value={value ?? defaultValue}
              onChange={onChange}
              inputClassName={clsx(
                `!h-fit !text-base !border-0 !bg-base-seasalt !rounded !py-3 !px-4 !w-full !outline-brand-baseBlue !outline-1 ${className ?? ''}`,
                { 'border border-system-error': name && errors[name] }
              )}
              countrySelectorStyleProps={{
                className: '',
                buttonClassName:
                  '!h-fit !border-0 !bg-base-seasalt !rounded-l !py-3 !px-4 !outline-brand-baseBlue !outline-1',
              }}
            />
            {name && errors[name] && (
              <p role="alert" className="text-system-error">
                <p
                  role="alert"
                  className="text-system-error text-sm text-left pt-1"
                >
                  {typeof errors?.[name]?.message === 'string'
                    ? errors[name]?.message
                    : ''}
                </p>
              </p>
            )}
          </div>
        );
      }}
    />
  );
};
