import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
}
export const Tooltip = ({ children, content }: TooltipProps) => {
  return (
    <Popover className="relative">
      <PopoverButton>{children}</PopoverButton>
      <PopoverPanel
        anchor="bottom"
        className="flex flex-col bg-base-raisinBlack/90 text-white p-2 rounded-lg mt-2"
      >
        {content}
      </PopoverPanel>
    </Popover>
  );
};
