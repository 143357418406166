export enum ActionTypes {
  AUTH_SAVE = 'auth/save',
  AUTH_UPDATE = 'auth/update',
  AUTH_LOGOUT = 'auth/logout',
  BUSINESS_SAVE_CATEGORIES = 'business/saveCategories',
  BUSINESS_LOAD_LIST = 'business/loadList',
  BUSINESS_ADD_BUSINESS = 'business/addBusiness',
  BUSINESS_UPDATE = 'business/updateBusiness',
  BUSINESS_DELETE = 'business/deleteBusiness',
  BUSINESS_SELECT = 'business/selectBusiness',
}
