import { Tabs } from '@app/components';
import { SettingsTabs, SettingTabs } from '@app/utils/consts';
import { useSettingsPage } from './Settings.hooks';
import { useEffect, useMemo } from 'react';
import { SettingsPlan } from './Subpages';
import { motion, AnimatePresence } from 'framer-motion';
import { PaymentMethods } from './Subpages/PaymentMethods';
import { useSearchParams } from 'react-router-dom';
import { OptionInterface } from '@app/models/util';

export const Settings = () => {
  const { activeSubpage, handleTabChange, isLoading } = useSettingsPage();
  const [searchParams] = useSearchParams();

  const render = useMemo(() => {
    switch (activeSubpage) {
      case SettingTabs.DETAILS:
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="container mx-auto p-4"
          >
            <div className="bg-white p-4 ">Business details</div>
          </motion.div>
        );
      case SettingTabs.MEMBERS:
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="container mx-auto p-4"
          >
            <div className="bg-white p-4">Team members</div>
          </motion.div>
        );
      case SettingTabs.PAYMENT_METHODS:
        return <PaymentMethods />;
      case SettingTabs.PLAN:
        return <SettingsPlan />;
      default:
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="container mx-auto p-4"
          >
            <div className="bg-white p-4 ">Business details</div>
          </motion.div>
        );
    }
  }, [activeSubpage]);

  useEffect(() => {
    if (searchParams.get('tab')) {
      handleTabChange(searchParams.get('tab') ?? SettingTabs.DETAILS);
    }
  }, []);

  return (
    <div className=" min-h-full">
      <div className="bg-white border-b border-b-base-ghostWhite ">
        <div className="container mx-auto pt-4 px-4">
          <h2 className="font-bold text-[28px] mb-2">Settings</h2>
          <Tabs
            tabs={SettingsTabs as unknown as OptionInterface[]}
            onClick={handleTabChange}
            defaultValue={activeSubpage}
          />
        </div>
      </div>
      <div className="relative min-h-full">
        {isLoading ? (
          <div className="container mx-auto p-4">
            <p className="text-base-raisinBlack font-bold">
              Fetching new information...
            </p>
          </div>
        ) : (
          <AnimatePresence initial={false}>{render}</AnimatePresence>
        )}
      </div>
    </div>
  );
};
