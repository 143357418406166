import { activate } from '@app/services/auth';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useValidateEmailPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState<string | undefined>();

  useEffect(() => {
    if (params?.token) {
      activate(params.token)
        .then(() => navigate('/login'))
        .catch((err) => {
          setValidationError(
            err?.response?.data?.message ??
              'An error occurred while validating your email'
          );
        });
    }
  }, []);
  return { validationError };
};
