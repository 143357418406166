import { apiInstance } from './api';
import { ApiRoutes } from './consts';

export const getBusinessInfo = async () => {
  return apiInstance.get(`${ApiRoutes.Business}/`);
};

export const createBusiness = async (data: {
  name: string;
  address: string;
  category: string;
  userId: string;
}) => {
  return apiInstance.post(`${ApiRoutes.Business}/save`, data);
};

export const updateBusiness = async () => {
  return apiInstance.patch(`${ApiRoutes.Business}/update`);
};

export const deleteBusiness = async () => {
  return apiInstance.delete(`${ApiRoutes.Business}/delete`);
};

export const getCategories = async () => {
  return apiInstance
    .get(`${ApiRoutes.Business}/categories`)
    .then((res) => res.data)
    .catch((err) => console.error(err));
};
