import { Button } from '@app/components';
import MessageIcon from '@app/assets/icons/message.svg?react';
import UsersIcon from '@app/assets/icons/grid/users.svg?react';
import OpenEnvelopeIcon from '@app/assets/icons/grid/envelope.svg?react';
import AutomationIcon from '@app/assets/icons/grid/automation.svg?react';
import { useDashboardHook } from './Dashboard.hooks';

export const Dashboard = () => {
  const { businessInfo, businessState } = useDashboardHook();

  return (
    <div>
      <div className="bg-white border-b border-b-base-ghostWhite ">
        <div className="container mx-auto p-4">
          <h2 className="font-bold text-[28px]">
            {businessState?.selected?.name}
          </h2>
        </div>
      </div>
      <div className="container mx-auto p-4">
        <div className="bg-white">
          <div className="p-4 flex items-center justify-between">
            <h4 className="font-bold">Onboarding</h4>
            <Button kind="secondary" className="font-normal">
              Open onboarding
            </Button>
          </div>
          <hr />
          <div className="p-4 flex gap-2 items-center justify-between">
            <div>
              <div className="bg-base-antiFlash h-12 w-12 flex items-center justify-center rounded-lg">
                <span className="text-2xl">O</span>
              </div>
            </div>
            <div className="mr-auto">
              <h4 className="font-semibold">
                Fill in more information about the business
              </h4>
              <p className="text-base-coolGray">
                For better AI generation, fill in more information about the
                business
              </p>
            </div>
            <div>
              <Button className="font-semibold  min-w-fit px-4">Open</Button>
            </div>
          </div>
        </div>
        <hr className="my-6" />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-white">
            <div className="flex gap-2 p-4">
              <div className="bg-base-antiFlash rounded-lg w-12 h-12 flex items-center justify-center">
                <MessageIcon width={24} height={24} />
              </div>
              <div>
                <span className="font-bold">400</span>
                <p className="text-base-coolGray text-sm">Reviews</p>
              </div>
            </div>
            <hr />
            <div className="flex gap-2 p-4">
              <div className="bg-base-antiFlash rounded-lg w-12 h-12 flex items-center justify-center">
                <UsersIcon width={24} height={24} />
              </div>
              <div>
                <span className="font-bold">{businessInfo?.customers}</span>
                <p className="text-base-coolGray text-sm">Customers</p>
              </div>
            </div>
            <hr />
            <div className="flex gap-2 p-4">
              <div className="bg-base-antiFlash rounded-lg w-12 h-12 flex items-center justify-center">
                <OpenEnvelopeIcon width={24} height={24} />
              </div>
              <div>
                <span className="font-bold">5,200</span>
                <p className="text-base-coolGray text-sm">Campaings sent</p>
              </div>
            </div>
            <hr />
            <div className="flex gap-2 p-4">
              <div className="bg-base-antiFlash rounded-lg w-12 h-12 flex items-center justify-center">
                <AutomationIcon width={24} height={24} />
              </div>
              <div>
                <span className="font-bold">5</span>
                <p className="text-base-coolGray text-sm">Active Automations</p>
              </div>
            </div>
          </div>
          <div className="col-span-2 grid grid-cols-2 gap-4">
            <div className="bg-white p-4">s</div>
            <div className="bg-white p-4">s</div>
            <div className="bg-white p-4">s</div>
            <div className="bg-white p-4">s</div>
          </div>
        </div>
      </div>
    </div>
  );
};
