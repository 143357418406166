import { Link, Outlet, UIMatch, useMatches } from 'react-router-dom';
import { SideDrawer } from '../SideDrawer/SideDrawer';
import { useEffect, useState } from 'react';
import { UserMenu } from '../UserMenu/UserMenu';
import { useAppSelector } from '@app/redux/hooks';
import DotsVerticalIcon from '@app/assets/icons/menu/dot-vertical.svg?react';
import UsageIcon from '@app/assets/icons/menu/usage.svg?react';
import NotificationIcon from '@app/assets/icons/menu/notification.svg?react';
import NotificationUnreadIcon from '@app/assets/icons/menu/notification-unread.svg?react';
import MenuIcon from '@app/assets/icons/menu.svg?react';
import clsx from 'clsx';

type HandleType = {
  crumb: (param?: string) => React.ReactNode;
};

export const DashboardLayout = () => {
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const businessState = useAppSelector((state) => state.business);
  const matches: UIMatch[] = useMatches();
  let crumbs = matches
    .filter((match) =>
      Boolean(match.handle && (match.handle as HandleType).crumb)
    )
    .map((match) =>
      (match.handle as HandleType).crumb(match.data as string | undefined)
    );

  const handleMobileMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    setTimeout(() => {
      setUnreadNotifications(20);
    }, 900);
  }, []);

  return (
    <section className="flex min-h-screen">
      <div className="w-56 hidden md:block">
        <SideDrawer />
      </div>
      <div
        className={clsx('w-56 block md:hidden fixed h-full transform', {
          '-translate-x-56': !showMenu,
          'translate-x-0 z-[90]': showMenu,
        })}
      >
        <SideDrawer />
      </div>
      {showMenu && (
        <div
          onClick={() => handleMobileMenu()}
          className="fixed w-full inset-0 h-full bg-black/30 z-50"
        />
      )}
      <div className="w-full">
        <div className="block md:hidden bg-white text-sm border-b">
          <div className="container h-12 p-4 mx-auto flex items-center justify-between font-medium">
            <div className="flex items-center justify-between gap-2">
              <button onClick={() => handleMobileMenu()}>
                <MenuIcon />
              </button>
              <div
                className="w-8 h-8 flex items-center justify-center font-bold text-lg rounded-lg"
                style={{ color: `#424244`, background: `#42424414` }}
              >
                {businessState?.selected?.name?.charAt(0)}
              </div>
              <h5 className="font-bold text-sm mr-auto">
                {businessState?.selected?.name}
              </h5>
              <Link to="/select-business">
                <DotsVerticalIcon />
              </Link>
            </div>
            <div>
              <UserMenu />
            </div>
          </div>
        </div>
        <div className="bg-white text-sm border-b hidden md:block">
          <div className="container h-12 p-4 mx-auto flex items-center justify-between font-medium">
            <h4>{crumbs?.[0]}</h4>
            <div className="flex gap-4 items-center">
              <button className="items-center hidden md:flex">
                <UsageIcon />
                Usage and Plan
              </button>
              <div className="hidden md:block">
                {unreadNotifications === 0 ? (
                  <NotificationIcon width={18} height={19} />
                ) : (
                  <NotificationUnreadIcon width={28} height={30} />
                )}
              </div>
              <div>
                <UserMenu />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </section>
  );
};
