import { CustomerInterface } from '@app/models/business';
import { ListInterface, TagInterface } from '@app/models/util';
import { getCustomer, updateCustomer } from '@app/services/customers';
import { getLists } from '@app/services/list';
import { getTag } from '@app/services/tag';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

export const useCustomerViewPage = () => {
  const navigate = useNavigate();
  const param = useParams();
  const [activeSubpage, setActivePage] = useState('1');
  const [customerUpdateModal, setCustomerUpdateModal] = useState(false);
  const customerUpdateForm = useForm();
  const {
    data: customerData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [`customer-${param.id}`],
    queryFn: () => getCustomer(param?.id as string),
    enabled: !!param.id,
  });
  const { data: listsAndTags } = useQuery({
    queryKey: ['all-lists-and-tags'],
    queryFn: async () =>
      await Promise.all([getLists(), getTag()])
        .then((responses) => {
          return {
            lists: responses?.[0]?.data?.lists,
            tags: responses?.[1]?.data?.tags,
          };
        })
        .catch((err) => err),
  });

  const handleTabChange = (value: string) => {
    setActivePage(value);
  };

  const handleCustomerModal = () => {
    setCustomerUpdateModal(!customerUpdateModal);
  };

  const { mutate: update, isPending } = useMutation({
    mutationKey: [`update-customer-${param.id}`],
    mutationFn: (data: Partial<CustomerInterface>) =>
      updateCustomer(param.id as string, data),
    onSuccess: () => {
      refetch();
      handleCustomerModal();
    },
  });

  useEffect(() => {
    if (customerData?.data?.customer) {
      customerUpdateForm.reset({
        ...customerData.data.customer,
        lists:
          customerData?.data?.customer?.lists?.map(
            (list: ListInterface) => list.id
          ) ?? [],
        tags:
          customerData?.data?.customer?.tags?.map(
            (tag: TagInterface) => tag.id
          ) ?? [],
      });
    }
  }, [customerData]);

  const handleCustomerUpdate = (values: FieldValues) => {
    update({ ...values });
  };

  return {
    navigate,
    param,
    customerData,
    isLoading,
    isPending,
    handleTabChange,
    customerUpdateForm,
    activeSubpage,
    customerUpdateModal,
    handleCustomerUpdate,
    handleCustomerModal,
    listsAndTags,
  };
};
