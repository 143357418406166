import ArrowBackIcon from '@app/assets/icons/back.svg?react';
import { Button, PlanCard, PlanUsageCard } from '@app/components';
import { GenericTable } from '@app/components/GenericTable/GenericTable';
import { GenericTableRow } from '@app/components/GenericTable/GenericTableRow';
import { formatPrice } from '@app/utils/utils';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { useYourCurrentPlan } from './YourCurrentPlan.hooks';
import { InvoiceInterface } from '@app/models/subscription';

export const YourCurrentPlan = () => {
  const { businessSubscription, invoicesResponse, isLoading } =
    useYourCurrentPlan();
  const navigate = useNavigate();

  return (
    <div>
      <div className="bg-white border-b border-b-base-ghostWhite ">
        <div className="container mx-auto p-4 flex justify-between items-center">
          <h2 className="font-bold text-[28px]">
            <button
              onClick={() => navigate('/settings?tab=plan')}
              className="mr-2"
            >
              <ArrowBackIcon />
            </button>
            Your Current Plan
          </h2>
          <div className="flex gap-2">
            <Button
              kind="secondary"
              className="text-sm font-semibold min-w-fit"
            >
              ...
            </Button>
          </div>
        </div>
      </div>
      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <PlanCard businessSubscription={businessSubscription} />
          </div>
          <div>
            <PlanUsageCard />
          </div>
          <div className="col-span-2">
            <GenericTable
              isLoading={isLoading}
              data={
                invoicesResponse?.data
                  ?.invoices as unknown as InvoiceInterface[]
              }
              renderRow={(data) =>
                data?.map((item, index) => {
                  const amount = (item.amount_due ?? item.amount_paid) / 100;

                  return (
                    <GenericTableRow
                      key={item.id}
                      id={String(index)}
                      render={
                        <>
                          <td className="p-4 font-bold">
                            {formatPrice(amount)}
                          </td>
                          <td className="p-4 uppercase">{item.status}</td>
                          <td className="p-4">
                            {(item?.description as string) ??
                              'Monthly subscription'}
                          </td>
                          <td className="p-4">Card</td>
                          <td className="p-4">
                            {DateTime.fromSeconds(
                              Number(item?.next_payment_attempt ?? '')
                            ).toISODate()}
                          </td>
                        </>
                      }
                    />
                  );
                })
              }
              total={3}
              handleLimitChange={() => {}}
              handleNextPage={() => {}}
              handlePrevPage={() => {}}
              headers={[
                { field: 'amount', label: 'amount' },
                { field: 'status', label: 'status' },
                { field: 'description', label: 'description' },
                { field: 'payment-method', label: 'payment method' },
                { field: 'date', label: 'date' },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
