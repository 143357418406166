import { confirmReseting, resetPassword } from '@app/services/auth';
import { newPasswordValidation } from '@app/utils/validations';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

export const useNewPasswordPage = () => {
  const [resetPasswordEnabled, setResetPasswordEnabled] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordChangeValid, setPasswordChangeValid] = useState(false);
  const [passwordError, setPasswordError] = useState<string | undefined>();
  const params = useParams();
  const resetPasswordForm = useForm({ resolver: newPasswordValidation });
  const navigate = useNavigate();

  const submitNewPassword = (values?: FieldValues) => {
    setPasswordError(undefined);
    if (values?.password && params?.token) {
      resetPassword(userEmail, values.password)
        .then(() => {
          setPasswordChangeValid(true);
          setTimeout(() => {
            navigate('/login');
          }, 5000);
        })
        .catch((err) => {
          setPasswordError(err?.data?.message);
          setResetPasswordEnabled(false);
        });
    }
  };

  useEffect(() => {
    if (params?.token) {
      setIsLoading(true);
      confirmReseting(params.token)
        .then((res) => {
          setUserEmail(res?.data?.email);
          setResetPasswordEnabled(true);
        })
        .catch((err) => {
          setUserEmail('');
          setResetPasswordEnabled(false);

          setPasswordError(err?.data?.message);
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  return {
    resetPasswordEnabled,
    resetPasswordForm,
    submitNewPassword,
    userEmail,
    passwordChangeValid,
    navigate,
    isLoading,
    passwordError,
  };
};
