import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
import { uploadCustomers } from '@app/services/customers';
import { useAppSelector } from '@app/redux/hooks';
import { toast } from 'react-toastify';
import { CustomerInterface } from '@app/models/business';

interface CustomerErrorsInterface {
  index: number;
  errors: string[];
}

export const useImportFromFilesPage = () => {
  const business = useAppSelector((state) => state.business);
  const importFileForm = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState<string | undefined>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [customerErrors, setCustomerErrors] = useState<
    CustomerErrorsInterface[] | undefined
  >();
  const [uploadedFile, setUploadedFile] = useState<
    Papa.ParseResult<unknown> | undefined
  >();

  const file = importFileForm.watch('file-drop');
  const requiredColumns = [
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
    'notes',
  ];

  const buildUserRequest = () => {
    if (uploadedFile) {
      const customersFromFile = (uploadedFile.data as string[][])
        .slice(1)
        .filter((customer) => {
          if (customer.length === 5) {
            return true;
          }
        })
        .map((customer) => {
          return {
            email: customer[2],
            firstName: customer[0],
            lastName: customer[1],
            phoneNumber: '+' + customer[3],
            notes: customer[4],
            businessId: business.selected?.id as string,
          };
        });

      return customersFromFile;
    }
  };

  const validateCustomers = (customers: Partial<CustomerInterface>[]) => {
    const usersWithIssues: CustomerErrorsInterface[] = [];

    customers.forEach((cus, index) => {
      const customerRow: CustomerErrorsInterface = {
        index: index - 1,
        errors: [],
      };

      if (!cus.email) {
        customerRow.errors.push('Email');
      }
      if (!cus.firstName) {
        customerRow.errors.push('First Name');
      }
      if (!cus.lastName) {
        customerRow.errors.push('Last Name');
      }

      if (customerRow.errors.length > 0) {
        usersWithIssues.push(customerRow);
      }
    });

    return usersWithIssues;
  };

  const handleUpload = () => {
    if (uploadedFile) {
      setIsUploading(true);
      const users = buildUserRequest();
      if (users && users.length > 0) {
        uploadCustomers(users)
          .then(() => {
            toast.success('Customers saved successfully');
            navigate('/customers/contacts');
          })
          .catch(() => {
            setError('Error while uploading customers');
          })
          .finally(() => {
            setIsUploading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (file) {
      const extension =
        typeof file === 'string' && file?.substring(file?.lastIndexOf('.') + 1);
      if (extension && extension !== 'csv') {
        setError('Invalid file format');
        setUploadedFile(undefined);
        setCustomerErrors(undefined);
        return;
      }

      if (file instanceof File) {
        Papa.parse(file, {
          complete: function (results) {
            setUploadedFile(results);
          },
          error: () => {
            setUploadedFile(undefined);
            setCustomerErrors(undefined);
          },
        });
      }
    } else {
      setUploadedFile(undefined);
      setCustomerErrors(undefined);
    }
  }, [file]);

  useEffect(() => {
    if (uploadedFile) {
      const customersFromFile = buildUserRequest();
      const customersChecked = validateCustomers(
        customersFromFile as Partial<CustomerInterface>[]
      );
      if (customersChecked.length > 0) {
        setCustomerErrors(customersChecked);
      }
    }
  }, [uploadedFile]);

  return {
    customerErrors,
    importFileForm,
    uploadedFile,
    navigate,
    handleUpload,
    error,
    isUploading,

    requiredColumns,
  };
};
