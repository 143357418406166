import Logo from '@app/assets/horizontal-logo.svg?react';
import { Button } from '@app/components';
import { BusinessSelect } from '@app/components/BusinessSelect/BusinessSelect';
import { useSelectBusinessPage } from './SelectBusiness.hooks';

export const SelectBusiness = () => {
  const { businessState } = useSelectBusinessPage();

  return (
    <div className="flex items-center justify-start flex-col min-h-screen py-6 ">
      <Logo width={139} height={110} className="mb-4" />
      <div className="mb-auto space-y-6 max-w-[600px] w-full ">
        <div>
          <h2 className="font-bold text-3xl text-center">
            Select your business
          </h2>
          <p className="text-base-coolGray text-center">
            Select the business you are going to work on now
          </p>
        </div>
        <ul className="flex gap-4 flex-col max-w-[600px] w-full">
          {businessState?.list?.map((item, index) => (
            <li key={index}>
              <BusinessSelect {...item} />
            </li>
          ))}
        </ul>
        <div className="flex justify-center pt-8">
          <Button to="/select-business/create-new">Create new business</Button>
        </div>
      </div>
    </div>
  );
};
