import { PaymentElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { Button } from '../Button/Button';
import { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

export const CardForm = ({
  stripe,
  elements,
  cancelAction,
}: {
  stripe: Stripe | null;
  elements: StripeElements | null;
  cancelAction: () => void;
}) => {
  const [error, setError] = useState<string | undefined>();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    const { error, setupIntent } = await stripe.confirmSetup({
      confirmParams: {
        payment_method: 'card',
      },
      redirect: 'if_required',
      elements,
    });

    if (error) {
      setError(error.message);
    }

    if (setupIntent?.status === 'succeeded') {
      toast.success('Payment method saved successfully');
      cancelAction();
    }
  };

  return (
    <div className="bg-white">
      <form onSubmit={handleSubmit} className="space-y-4">
        <PaymentElement />
        {error && (
          <div className="text-system-error font-bold py-4">{error}</div>
        )}
        <div className="flex items-center justify-between">
          <Button
            onClick={cancelAction}
            kind="secondary"
            type="button"
            disabled={!stripe}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={!stripe}>
            Save payment method
          </Button>
        </div>
      </form>
    </div>
  );
};
