import { apiInstance } from './api';
import { ApiRoutes } from './consts';

export interface RegisterInterface {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber?: string;
  business: {
    name: string;
    address: string;
    category: string;
  };
}

export const register = async (data: RegisterInterface) => {
  return apiInstance.post(`${ApiRoutes.Auth}/register`, data);
};

export const activate = async (link: string) => {
  return apiInstance.get(`${ApiRoutes.Auth}/activate/${link}`);
};

export const login = async (email: string, password: string) => {
  return apiInstance.post(`${ApiRoutes.Auth}/login`, {
    email,
    password,
  });
};

export const refresh = async () => {
  return apiInstance.get(`${ApiRoutes.Auth}/refresh`);
};

export const verifyPasswordEmail = async (email: string) => {
  return apiInstance.post(`${ApiRoutes.Auth}/verify-email`, {
    email,
  });
};

export const confirmReseting = async (token: string) => {
  return apiInstance.get(`${ApiRoutes.Auth}/confirm-reseting/${token}`);
};

export const resetPassword = async (email: string, password: string) => {
  return apiInstance.post(`${ApiRoutes.Auth}/reset-password`, {
    email,
    password,
  });
};

export const loginWithGoogle = async () => {
  window.location.href = `${import.meta.env.VITE_APP_API_BASE}/auth/google/login`;
};

export const registerWithGoogle = async () => {
  window.location.href = `${import.meta.env.VITE_APP_API_BASE}/auth/google/register`;
};

export const registerGoogleCallback = async () => {
  return apiInstance.get(`${ApiRoutes.Auth}/google/register/callback`);
};

export const googleCallback = async () => {
  return apiInstance.get(`${ApiRoutes.Auth}/google/callback`);
};
