import {
  BaseDialog,
  Button,
  HookedForm,
  PaymentMethodForm,
  RadioField,
} from '@app/components';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCards } from '@app/services/user';
import { Loading } from '@app/components/Loading/Loading';
import { RadioGroup } from '@headlessui/react';
import { UserCardInterface } from '@app/models/user';
import CardsIllustration from '@app/assets/illustrations/cards.svg?react';
import AddCardIcon from '@app/assets/icons/add-card.svg?react';
import AddIcon from '@app/assets/icons/add.svg?react';
import CloseIcon from '@app/assets/icons/close.svg?react';

export const PaymentMethods = () => {
  const [showCardForm, setShowCardForm] = useState(false);

  const {
    data: cardsResponse,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['cards'],
    queryFn: getCards,
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container mx-auto p-4 space-y-6"
    >
      {isLoading && <Loading />}
      {!isLoading && cardsResponse?.data?.cards?.length > 0 && (
        <HookedForm mode="onChange" onChange={console.log}>
          <RadioGroup
            name="defaultCard"
            onChange={console.log}
            className="grid grid-cols-1 md:grid-cols-3 gap-6"
          >
            {cardsResponse?.data?.cards?.map((card: UserCardInterface) => (
              <div className="bg-white p-4 rounded-lg space-y-4" key={card?.id}>
                <div>
                  <span className="capitalize font-bold">{card?.brand}</span>
                </div>
                <hr />

                <div className="flex justify-between items-center">
                  <span className="text-base-coolGray text-xs">
                    CARD ENDING IN
                  </span>
                  <span className="text-sm font-medium">
                    •••• {card?.last4}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-base-coolGray text-xs">
                    CARDHOLDER NAME
                  </span>
                  <span className="text-sm font-medium">Test User</span>
                </div>

                <div>
                  <RadioField
                    label="Set this payment method as active now"
                    name="defaultCard"
                    value={card.id}
                  />
                </div>
              </div>
            ))}
            <div
              className="bg-white/80 p-4 rounded-lg border border-dashed flex items-center justify-center cursor-pointer"
              onClick={() => setShowCardForm(true)}
            >
              <AddIcon className="[&_path]:stroke-brand-baseBlue mr-2" />
              <span className="text-brand-baseBlue font-bold">
                Add payment method
              </span>
            </div>
          </RadioGroup>
        </HookedForm>
      )}

      {!isLoading && cardsResponse?.data?.cards?.length === 0 && (
        <div className="flex items-center justify-center flex-col max-w-[350px] mx-auto space-y-4 h-full mt-20">
          <CardsIllustration />
          <p className="font-bold">No payment methods</p>
          <p className="text-sm text-base-coolGray text-center">
            Add the payment method that will be used to
            <br /> pay for the plan
          </p>
          <Button
            leftNode={<AddCardIcon />}
            onClick={() => {
              setShowCardForm(!showCardForm);
            }}
            className="font-medium"
          >
            Add payment method
          </Button>
        </div>
      )}
      <BaseDialog
        open={showCardForm}
        onClose={() => setShowCardForm(!showCardForm)}
      >
        <div className="border-b border-b-base-ghostWhite p-6  flex gap-2 items-start justify-between">
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <AddCardIcon width={24} height={24} />
          </div>
          <div className="mr-auto">
            <h5 className="font-bold text-lg leading-tight">Add your card</h5>
            <p className="text-base-coolGray text-sm">
              Save your card details to pay for your choosen plan
            </p>
          </div>
          <div>
            <button type="button" onClick={() => setShowCardForm(false)}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6 pb-6 m-0">
          {showCardForm && (
            <PaymentMethodForm
              cancelAction={() => {
                setShowCardForm(!showCardForm);
                refetch();
              }}
            />
          )}
        </div>
      </BaseDialog>
    </motion.div>
  );
};
