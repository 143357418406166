import { useAppSelector } from '@app/redux/hooks';
import { getInvoices } from '@app/services/plans';
import { useQuery } from '@tanstack/react-query';

export const useYourCurrentPlan = () => {
  const auth = useAppSelector((state) => state.auth);
  const business = useAppSelector((state) => state.business);
  const businessSubscription = auth?.user?.subscriptions?.find(
    (sub) => sub.businessId === business?.selected?.id
  );

  const { data: invoicesResponse, isLoading } = useQuery({
    queryKey: ['get-invoices'],
    queryFn: () =>
      getInvoices({
        subId: businessSubscription?.stripeSubscriptionId as string,
      }),
    enabled: !!businessSubscription,
  });

  return {
    businessSubscription,
    invoicesResponse,
    isLoading,
  };
};
