import { BaseDialog, Button, HookedForm, InputField } from '@app/components';
import { ContactUsEmail, IntegrationType } from '@app/utils/consts';
import { useIntegrationsReviews } from './IntegrationsReviews.hooks';
import BulbIcon from '@app/assets/icons/menu/bulb.svg?react';
import AddIcon from '@app/assets/icons/add.svg?react';
import CloseIcon from '@app/assets/icons/close.svg?react';
import { IntegrationMenu } from '@app/components/IntegrationMenu/IntegrationMenu';

export const IntegrationsReviews = () => {
  const {
    saveIntegrationForm,
    saveIntegrationSubmit,
    handleIntegrationModal,
    integrationFormModal,
    listOfIntegrations,
    deleteIntegrationAction,
    handleUpdateIntegrationModal,
    updateIntegrationFormModal,
    updateIntegrationForm,
    updateIntegrationSubmit,
  } = useIntegrationsReviews();

  return (
    <>
      <div>
        <div className="bg-white border-b border-b-base-ghostWhite ">
          <div className="container mx-auto p-4 flex flex-col md:flex-row justify-between gap-4">
            <div>
              <h2 className="font-bold text-[28px]">Review Platforms</h2>
              <p className="text-base-coolGray">
                Choose the platforms you want to use for gathering reviews.
                Integrating with these platforms
                <br /> will enable you to send requests and respond to reviews
                on this service.
              </p>
            </div>
            <div>
              <div className="bg-brand-lightBlue3 p-4  rounded-lg text-sm font-medium text-brand-darkBlue1 flex gap-2">
                <div>
                  <BulbIcon
                    className="[&_path]:stroke-brand-baseBlue"
                    width={20}
                    height={20}
                  />
                </div>
                <div>
                  <p>
                    Want to integrate a platform that is not listed
                    <br /> here? Contact us!
                    <br /> And we will consider the possibility of adding it
                    <br /> to the list of integrations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto p-4">
          <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4">
            {listOfIntegrations.map((integration) => {
              return (
                <div className="bg-white p-4 rounded-lg space-y-4">
                  <div className="flex items-center gap-2">
                    <div className="w-10 h-10 rounded-lg bg-base-antiFlash flex items-center justify-center">
                      {integration.Icon && <integration.Icon />}
                    </div>
                    <p className="text-base-davyGray font-medium capitalize">
                      {integration.title.replace('_', ' ')}
                    </p>
                    {integration.hasConnection && (
                      <div className="w-10 h-10 rounded-lg bg-base-antiFlash flex items-center justify-center ml-auto">
                        <IntegrationMenu
                          id={integration.title}
                          deleteAction={() =>
                            deleteIntegrationAction(integration.title)
                          }
                          updateAction={() =>
                            handleUpdateIntegrationModal(integration.title)
                          }
                        />
                      </div>
                    )}
                  </div>
                  <p className="text-base-davyGray text-sm">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea com.
                  </p>
                  <div className="text-right">
                    <Button
                      disabled={!integration.available}
                      leftNode={<AddIcon />}
                      onClick={() =>
                        handleIntegrationModal(IntegrationType.YELP)
                      }
                      className="ml-auto min-w-fit pr-4"
                    >
                      Connect
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="my-16">
            <div className="max-w-xl mx-auto border-dashed border-2 border-base-lavender p-6 space-y-6 text-center rounded-xl">
              <h5 className="font-bold">Don't see your review platform?</h5>
              <p className="text-base-davyGray">
                Click here and request that it be added
              </p>
              <a href={`mailto:${ContactUsEmail}`} className="inline-block">
                <Button className="mx-auto">Contact us</Button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <BaseDialog
        open={Boolean(updateIntegrationFormModal)}
        onClose={() => handleUpdateIntegrationModal()}
      >
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <AddIcon
              width={24}
              height={24}
              className="[&_path]:stroke-base-davyGray"
            />
          </div>
          <div className="mr-auto">
            <h5 className="font-bold text-lg leading-tight">
              Update integration information
            </h5>
            <p className="text-base-coolGray text-sm">
              Update the information to connect to the platform.
            </p>
          </div>
          <div>
            <button
              type="button"
              onClick={() => handleUpdateIntegrationModal()}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6 pb-4 space-y-4">
          <p className="text-base-davyGray text-sm">
            Some Platforms require some information so that we can connect with
            them, this can be an API Key or Business ID (or business alias)
          </p>
          <HookedForm
            formHook={updateIntegrationForm}
            onSubmit={updateIntegrationSubmit}
            id="updateIntegration"
          >
            <input
              {...updateIntegrationForm.register('type')}
              type="hidden"
              name="type"
              value={updateIntegrationFormModal}
            />
            <InputField
              name="connectionId"
              placeholder="Enter you API Key or Business ID here"
            />
          </HookedForm>
        </div>
        <div className="flex gap-4 pb-6 px-6">
          <Button
            kind="secondary"
            className="w-full"
            onClick={() => handleUpdateIntegrationModal()}
          >
            Cancel
          </Button>
          <Button form="updateIntegration" className="w-full" type="submit">
            Save
          </Button>
        </div>
      </BaseDialog>
      <BaseDialog
        open={Boolean(integrationFormModal)}
        onClose={() => handleIntegrationModal()}
      >
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <AddIcon
              width={24}
              height={24}
              className="[&_path]:stroke-base-davyGray"
            />
          </div>
          <div className="mr-auto">
            <h5 className="font-bold text-lg leading-tight">
              Add integration information
            </h5>
            <p className="text-base-coolGray text-sm">
              Add required information to connect to the platform.
            </p>
          </div>
          <div>
            <button type="button" onClick={() => handleIntegrationModal()}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6 pb-4 space-y-4">
          <p className="text-base-davyGray text-sm">
            Some Platforms require some information so that we can connect with
            them, this can be an API Key or Business ID (or business alias)
          </p>
          <HookedForm
            formHook={saveIntegrationForm}
            onSubmit={saveIntegrationSubmit}
            id="saveIntegration"
          >
            <input
              {...saveIntegrationForm.register('type')}
              type="hidden"
              name="type"
              value={integrationFormModal}
            />
            <InputField
              name="connectionId"
              placeholder="Enter you API Key or Business ID here"
            />
          </HookedForm>
        </div>
        <div className="flex gap-4 pb-6 px-6">
          <Button
            kind="secondary"
            className="w-full"
            onClick={() => handleIntegrationModal()}
          >
            Cancel
          </Button>
          <Button form="saveIntegration" className="w-full" type="submit">
            Save
          </Button>
        </div>
      </BaseDialog>
    </>
  );
};
