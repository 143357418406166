import { useAppDispatch } from '@app/redux/hooks';
import { ActionTypes } from '@app/redux/types';
import { getPlans } from '@app/services/plans';
import { SettingTabs } from '@app/utils/consts';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useSettingsPage = () => {
  const [activeSubpage, setActivePage] = useState(SettingTabs.DETAILS);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isPending, mutate: updateSubscriptions } = useMutation({
    mutationKey: ['update-subscriptions'],
    mutationFn: () => getPlans(),
    onSuccess: (res) => {
      dispatch({ type: ActionTypes.AUTH_UPDATE, payload: res?.data });
    },
    onError: (error) => {
      toast.error('Error updating subscriptions');
      console.error(error);
    },
  });

  const handleTabChange = (value: string) => {
    setSearchParams((params) => {
      params.set('tab', value);
      return params;
    });

    setActivePage(value as SettingTabs);
  };

  useEffect(() => {
    updateSubscriptions();
  }, []);

  return {
    activeSubpage,
    handleTabChange,
    updateSubscriptions,
    isLoading: isPending,
    searchParams,
  };
};
