export const ApiBaseRoute = import.meta.env.VITE_APP_API_BASE;

export const ApiRoutes = {
  Auth: '/auth',
  User: '/user',
  Plan: '/subscription',
  Business: '/business',
  Lists: '/lists',
  Tags: '/tags',
  Customers: '/customers',
  Integrations: '/integrations',
};
