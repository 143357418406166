import { IntegrationType } from '@app/utils/consts';
import { apiInstance } from './api';
import { ApiRoutes } from './consts';

export const getIntegrations = async () => {
  return apiInstance.get(`${ApiRoutes.Business}${ApiRoutes.Integrations}/all`);
};

export const getIntegration = async (type: string) => {
  return apiInstance.get(
    `${ApiRoutes.Business}${ApiRoutes.Integrations}/connection/${type}`
  );
};

export const createIntegration = async (data: {
  type: IntegrationType;
  connectionId?: string;
}) => {
  return apiInstance.post(
    `${ApiRoutes.Business}${ApiRoutes.Integrations}`,
    data
  );
};

export const deleteIntegration = async (id: string) => {
  return apiInstance.delete(
    `${ApiRoutes.Business}${ApiRoutes.Integrations}/${id}`
  );
};

export const updateIntegration = async (
  id: string,
  data: {
    connectionId?: string;
  }
) => {
  return apiInstance.patch(
    `${ApiRoutes.Business}${ApiRoutes.Integrations}/${id}`,
    data
  );
};
