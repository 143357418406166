import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { CardForm } from '../CardForm/CardForm';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { createIntent } from '@app/services/plans';

const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_KEY);

const ElementsWrapper = ({ cancelAction }: { cancelAction: () => void }) => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CardForm
          stripe={stripe}
          elements={elements}
          cancelAction={cancelAction}
        />
      )}
    </ElementsConsumer>
  );
};

export const PaymentMethodForm = ({
  cancelAction,
}: {
  cancelAction: () => void;
}) => {
  const [clientSecret, setClientSecret] = useState('');
  const [loadingStripe, setLoadingStripe] = useState(true);

  useEffect(() => {
    const fetchClientSecret = async () => {
      setLoadingStripe(true);
      const response = await createIntent();

      setClientSecret(response.data.intent);
      setLoadingStripe(false);
    };

    fetchClientSecret();
  }, []);

  return (
    clientSecret && (
      <Elements options={{ clientSecret }} stripe={stripePromise}>
        {loadingStripe && <p>Loading Stripe...</p>}
        {!loadingStripe && <ElementsWrapper cancelAction={cancelAction} />}
      </Elements>
    )
  );
};
