import { UserInterface } from '@app/models/user';
import { apiInstance } from './api';
import { ApiRoutes } from './consts';

export const update = async (data: Partial<UserInterface>) => {
  return apiInstance.post(`${ApiRoutes.User}/update-user`, data);
};

export const getProfile = async () => {
  return apiInstance.get(`${ApiRoutes.User}/user-profile`);
};

export const getBusinesses = async () => {
  return apiInstance.get(`${ApiRoutes.User}/businesses`);
};

export const getUserWithBusinesses = async () => {
  return apiInstance.get(`${ApiRoutes.User}/me`);
};

export const postUserPhoto = async (data: File) => {
  var formData = new FormData();
  formData.append('file', data);

  return apiInstance.post(`${ApiRoutes.User}/upload-photo`, formData);
};

export const updateUserPhoto = async (data: FormData) => {
  return apiInstance.post(`${ApiRoutes.User}/update-photo`, data);
};

export const getUserPhoto = async () => {
  return apiInstance.get(`${ApiRoutes.User}/photo`);
};

export const getSubscriptions = async () => {
  return apiInstance.get(`${ApiRoutes.User}/subscriptions`);
};

export const getCards = async () => {
  return apiInstance.get(`${ApiRoutes.User}/cards`);
};

export const updateEmail = async (data: { email: string }) => {
  return apiInstance.put(`${ApiRoutes.User}/update-email`, data);
};

export const checkPhone = async (data: { phoneNumber: string }) => {
  return apiInstance.post(`${ApiRoutes.User}/check-phone`, data);
};

export const validatePhone = async (data: { phone: string; code: string }) => {
  return apiInstance.post(`${ApiRoutes.User}/validate-phone`, data);
};
